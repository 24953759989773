import React, { useState, useEffect } from 'react';
import axios from '../../api/Axios';
import Loader from '../Loader'
const TopRatedAreas = (props) => {
  const storedDate = sessionStorage.getItem('qd-tool-search-date') ? sessionStorage.getItem('qd-tool-search-date') : '';
  const storedArea = sessionStorage.getItem('qd-tool-search-area') ? sessionStorage.getItem('qd-tool-search-area') : '';
  
  const [sortGroup, setSortGroup] = useState('1');
  const [sortBy, setSortBy] = useState('area_rank');
  const [sortDir, setSortDir] = useState('asc');
  const [areaRankings, setAreaRankings] = useState();
  const [filteredAreaRankings, setFilteredAreaRankings] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); 
  const [searchDate, setSearchDate] = useState(storedDate);
  const [searchAreaInput, setSearchAreaInput] = useState(storedArea);

  
  
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const nextMonth = new Date();
  nextMonth.setDate(new Date().getDate() + 31);
  const minDate = tomorrow.toISOString().split('T')[0];
  const maxDate = nextMonth.toISOString().split('T')[0];



 
  const handleSortOrder = (col, group) => {
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    setSortBy(col);
    setSortGroup(group);
    
    setAreaRankings(
      areaRankings.sort((a, b) => {
        return (
          a[col].toString().localeCompare(b[col].toString(), "en", {
           numeric: true,
          }) * (sortDir === "asc" ? 1 : -1)
         );
      })
    );
  };
  
  const fetchAreaRankings = async (date = storedDate) => {
    try {
      setAreaRankings(null);
      setFilteredAreaRankings(null);
      setLoading(true);
      setError(false);
      await axios.get('/area_priorities', {
        params: {
          service_date: date
        }
      })
        .then(response => {
          const data = eval(response.data)
          setAreaRankings(data);
          const area = sessionStorage.getItem('qd-tool-search-area') ? sessionStorage.getItem('qd-tool-search-area') : '';
       
          const rankings = data
          .filter((result) => result.area && result.area.toLowerCase().includes(area.toLowerCase()))
          .reduce((acc, x) => {
            acc[x['cluster_rank']] = [...(acc[x['cluster_rank']] || []), x];
            return acc;
          }, {});

          setFilteredAreaRankings(rankings);
          
          
          setLoading(false);
          setError(false);
        })
        .catch(error => {
          console.log(error);
          setAreaRankings(null);
          setFilteredAreaRankings(null);
          setLoading(false);
          setError(true);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const handleSearchDate = async (date) => {
    setSearchDate(date);
    sessionStorage.setItem('qd-tool-search-date', date);
    //  setSearchAreaInput('');
     fetchAreaRankings(date);
  }

  const handleClear = () => {
    setSearchDate('');
    sessionStorage.removeItem('qd-tool-search-date');
    sessionStorage.removeItem('qd-tool-search-area');
    setSearchAreaInput('');
    fetchAreaRankings();
  }

  
  useEffect(() => {
    const date = sessionStorage.getItem('qd-tool-search-date');
    const area = sessionStorage.getItem('qd-tool-search-area');
   
    if (date) {
      setSearchDate(date);
      fetchAreaRankings(date);
    }else{
      fetchAreaRankings();
    }
    if(area){
      setSearchAreaInput(area);
    //  handleAreaSearch(area);
    }
  }, []);
 

  const handleAreaSearch = (val) => {
    const rankings = areaRankings
    .filter((result) => result.area && result.area.toLowerCase().includes(val.toLowerCase()))
    .reduce((acc, x) => {
      acc[x['cluster_rank']] = [...(acc[x['cluster_rank']] || []), x];
      return acc;
    }, {});
    setFilteredAreaRankings(rankings);
  }
  const setSearchDateField = (val) => {
    setSearchDate(val)
    sessionStorage.setItem('qd-tool-search-date', val);
  }

  return (
    <div>
        {(loading) && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>}
    
        {(!loading) && 
    <div className='flex justify-between md:py-6 mx-auto py-4 flex-wrap w-full'>

      <div className='w-full flex gap-5 md:w-auto'>
        <div className='flex-1 md:flex-none'>
          <input
            type="date"
            placeholder="Service Date"
            value={searchDate}
            onChange={(e) => setSearchDateField(e.target.value)
            }
            min={minDate}
            max={maxDate}
            className="w-full px-2 border rounded  border-primary/20 focus:border-primary/80 focus:bg-primary-05 focus:ring-0 text-center"
          />
        </div>
        <div>
        <button onClick={() => handleSearchDate(searchDate)}
        className='bg-white border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
        </button>
          <button onClick={handleClear}
          className='bg-white border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </button>
        </div>
      </div>
    
      <div className='w-full md:w-auto md:py-0 py-2'>
        <input
          type="text"
          placeholder="Area"
          value={searchAreaInput}
          onChange={(e) => {
            setSearchAreaInput(e.target.value);
            sessionStorage.setItem('qd-tool-search-area', e.target.value);
            handleAreaSearch(e.target.value);
          }}
          
          className="w-full px-2 border rounded  border-primary/20 focus:border-primary/80 focus:bg-primary-05 focus:ring-0"
        />
    
      </div>
    </div>
}
    


    
{(filteredAreaRankings) &&  filteredAreaRankings.length !== 0 &&
Object.entries(filteredAreaRankings).map(([cluster_rank, areas]) => {
          return (
            <div className='w-full rounded bg-white p-4 mb-4 shadow-xl' key={cluster_rank}>
              <div className="md:text-xl text-md font-semibold py-2">Cluster {cluster_rank}</div>
              <div className="table align-middle w-full border pb-6 border-transparent">
                <div className="table-row ">
  
<div className="relative table-cell overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 text-left md:text-xs text-xxs leading-4 
                font-bold  uppercase tracking-wider flex items-center focus:outline-none cursor-pointer
                border-b border-primary/10"
                onClick={() => {handleSortOrder('area', cluster_rank)}}>
                Area
                {sortBy === 'area' && sortGroup === cluster_rank  ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
               </div>
</div>
<div className="relative table-cell  overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 md:text-xs text-xxs leading-4 text-center
                font-bold  uppercase tracking-wider focus:outline-none cursor-pointer
                border-b border-primary/10"
                onClick={() => {handleSortOrder('area_rank', cluster_rank)}}>
                  <span className="hidden md:block">
                Area Rank
                {sortBy === 'area_rank' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                </span>
                <span className="md:hidden">
                  Rank
                  {sortBy === 'area_rank' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                </span>
               </div>
</div>
<div className="relative table-cell  overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 md:text-xs text-xxs leading-4 text-center
                font-bold  uppercase tracking-wider focus:outline-none cursor-pointer
                border-b border-primary/10" 
                onClick={() => {handleSortOrder('very_high', cluster_rank)}}>
                  <span className="hidden md:block">
                    Very High
                    {sortBy === 'very_high' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                    </span>
                  <span className="md:hidden">
                    V.High
                    {sortBy === 'very_high' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                    </span>
                  
                  </div>
</div>
<div className="relative table-cell  overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 text-center md:text-xs text-xxs leading-4 
                font-bold  uppercase tracking-wider  focus:outline-none cursor-pointer
                border-b border-primary/10"
                 onClick={() => {handleSortOrder('high', cluster_rank)}}>
                High
                {sortBy === 'high'  && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                </div>
</div>
<div className="relative table-cell overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 text-center md:text-xs text-xxs leading-4 
                font-bold  uppercase tracking-wider  focus:outline-none cursor-pointer
                border-b border-primary/10"
                onClick={() => {handleSortOrder('medium', cluster_rank)}}>
                <span className="hidden md:block">
                  Medium
                  {sortBy === 'medium' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                  </span>
                <span className="md:hidden">
                  Med
                  {sortBy === 'medium' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                  </span>
                
               
                  </div>
</div>
<div className="relative table-cell overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 text-center md:text-xs text-xxs leading-4 
                font-bold  uppercase tracking-wider  focus:outline-none cursor-pointer
                border-b border-primary/10"
                onClick={() => {handleSortOrder('low', cluster_rank)}}>
                Low
                {sortBy === 'low' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                
                  </div>
</div>
<div className="relative table-cell overflow-hidden align-top whitespace-nowrap">
<div className="w-full h-full sm:px-2 px-1 pb-1 pt-4 text-center md:text-xs text-xxs leading-4 
                font-bold  uppercase tracking-wider  focus:outline-none cursor-pointer
                border-b border-primary/10"
                onClick={() => {handleSortOrder('very_low', cluster_rank)}}>
                <span className="hidden md:block">
                  Very Low 
                  {sortBy === 'very_low' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                  </span>
                <span className="md:hidden">
                  V.Low 
                  {sortBy === 'very_low' && sortGroup === cluster_rank ? ( sortDir === 'asc' ? '↑' : '↓' ): ''}
                  </span>

                </div>
</div>




</div>

              {areas
              .sort((a, b) => {
                return (
                  sortGroup === cluster_rank &&
                  a[sortBy].toString().localeCompare(b[sortBy].toString(), "en", {
                   numeric: true,
                  }) * (sortDir === "asc" ? 1 : -1)
                
                
                );
              })
              
              
              .map((result, key) => {
                return (
                  
                  <div key={key} className="table-row p-0 hover:bg-primary/10">
                 
                    
                               <div className="border-b border-secondary-200 relative table-cell max:w-4/12 md:px-2 py-1 md:text-sm truncate text-xs text-ellipsis text-left ">
                                 <div 
                                 onClick={() => {
                                  props.handleClientSearch(result.area, result.priority, searchDate);
                                }}
                                 className='cursor-pointer truncate text-xs text-ellipsis md:text-sm hover:text-danger'>
                                 <div className='max-w-10 md:max-w-full whitespace-pre-line md:whitespace-nowrap'>
                                 {result.area}
                                 </div>
                                 </div>
                               
                               </div>
                               <div className="border-b border-secondary-200   table-cell  sm:px-2 px-2 py-1 whitespace-nowrap text-sm     text-center   ">
                    {result.area_rank}
                               </div>
                               <div className="border-b border-secondary-200   table-cell  sm:px-2 px-2 py-1 whitespace-nowrap text-sm     text-center   ">
                               <div 
                               onClick={() => {            
                                props.handleClientSearch(result.area, 'very high', searchDate);
                              }}
                              className='bg-succes rounded text-white font-normal  sm:px-2 px-2 py-2 text-xs inline-block md:w-20 cursor-pointer hover:bg-succes/60'>
                                {result.very_high}
                               </div>
                               </div>
                               <div className="border-b border-secondary-200   table-cell  sm:px-2 px-2 py-1 whitespace-nowrap text-sm      text-center   ">
                               <div 
                               onClick={() => {
                                props.handleClientSearch(result.area, 'high', searchDate);
                              }}
                              className='bg-succes/60 rounded text-white font-normal sm:px-2 px-2 py-2 text-xs inline-block md:w-20 cursor-pointer hover:bg-succes/30'>
                                 {result.high}
                                 </div>
                               </div>
                               <div className="border-b border-secondary-200   table-cell  sm:px-2 px-2 py-1 whitespace-nowrap text-sm      text-center   ">
                               <div 
                               onClick={() => {
                                props.handleClientSearch(result.area, 'medium', searchDate);
                              }}
                              className='bg-succes/30 rounded text-succes font-normal sm:px-2 px-2 py-2 text-xs inline-block md:w-20 cursor-pointer hover:bg-succes/15'>
                               {result.medium}
                               </div>
                               </div>
                               <div className="border-b border-secondary-200   table-cell  smd:px-2 px-2 py-1 whitespace-nowrap text-sm      text-center   ">
                               <div 
                               onClick={() => {
                                props.handleClientSearch(result.area, 'low', searchDate);
                              }}
                              className='bg-danger/20 rounded text-danger font-normal sm:px-2 px-2 py-2 text-xs inline-block md:w-20 cursor-pointer hover:bg-danger/10'>
                               {result.low}
                               </div>
                               </div>
                               <div className="border-b border-secondary-200   table-cell  sm:px-2 px-2 py-1 whitespace-nowrap text-sm      text-center   ">
                               <div 
                               onClick={() => {
                               props.handleClientSearch(result.area, 'very low', searchDate);
                              }}
                              className='bg-danger/10 rounded text-danger font-normal sm:px-2 px-2 py-2 text-xs inline-block md:w-20 cursor-pointer hover:bg-slate'>
                               {result.very_low}
                               </div>
                               </div>
                             
                             </div> 
                )
              })}
              </div>
              
              
              
            </div>
          )
        })}
       

       
 
    </div>
  );
};

export default TopRatedAreas;

