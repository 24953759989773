import React from "react";
function Header(props) {
    const titles = [];

    for (let i = 0; i < 7; i++) {
        titles.push(

            <div key={i}
                className="border-r border-white flex flex-col text-left bg-danger text-white 
                md:py-11
                py-6
                px-6
                md:text-lg
                text-sm 
                font-bold capitalize"
            >
                 <span className="lg:hidden block">
                    {props.now.weekday(i).format("ddd")}
                </span>
               <span className="lg:block hidden">
                    {props.now.weekday(i).format("dddd")}
                </span>
            </div>

        );
    }
    return <div className="grid grid-cols-7  bg-white">

        {titles}
    </div>
        ;




}
export default Header;
