import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import useToken from '../../Components/Customer/useToken';
import useAccount from '../../Components/Customer/useAccount';
import Term from '../../Components/Term';
import SiteUsageComponent from '../../Components/SiteUsageComponent';

function LandingPage(props) {
  const { account, setAccount } = useAccount();
  const { token, setToken } = useToken();
  const skipPage = localStorage.getItem('qd-tool-skip-option') ? localStorage.getItem('qd-tool-skip-option') : '';
  
  let navigate = useNavigate();
  useEffect(() => {
    if (token || skipPage) {
      navigate("/home");
    }
  }, [skipPage, token]);
  const handleLogin = () => {
    navigate("/home");
  }

  const handleSkip = () => {
    localStorage.setItem('qd-tool-skip-option', true);
    navigate("/home");
  }

  




  return (

    <div className="">
      <header className="bg-primary">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="bg-danger md:w-52 w-28 md:p-5 p-2">
            <img src="../Logo.png" alt="logo" className="md:w-11/12 md:mx-auto" />
          </div>
          <div className="relative">
            <div className="relative">
              <button
                className="text-white hover:text-white/50 group gap-1 md:text-base text-xs font-normal flex cursor-pointer"
                onClick={handleLogin}
              >
                Login

                <svg xmlns="http://www.w3.org/2000/svg" className='ml-2' width="18" height="21" viewBox="0 0 18 21" fill="none">
                  <g clipPath="url(#clip0_528_9387)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.80819 2.20411C6.84182 2.20411 5.24256 3.81843 5.24256 5.80331C5.24256 7.78819 6.84182 9.40248 8.80819 9.40248C10.7746 9.40248 12.3738 7.78819 12.3738 5.80331C12.3738 3.81843 10.7746 2.20411 8.80819 2.20411ZM8.80819 11.1059C5.91096 11.1059 3.55469 8.72744 3.55469 5.80296C3.55469 2.87845 5.91096 0.5 8.80819 0.5C11.7055 0.5 14.0617 2.87845 14.0617 5.80296C14.0617 8.72744 11.7055 11.1059 8.80819 11.1059Z" fill="white" fillOpacity="1" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.2214 19.3039C15.2082 18.1133 14.0339 17.1107 12.7425 16.4209C11.4526 15.732 10.0439 15.363 8.64413 15.4245C7.24442 15.4513 5.87968 15.9057 4.6598 16.6509C3.43829 17.3985 2.35594 18.4341 1.43798 19.6345C1.15152 20.0133 0.638013 20.065 0.292148 19.7504C-0.0480186 19.4412 -0.0968465 18.8877 0.175776 18.5098C1.18 17.1294 2.42511 15.9227 3.88343 15.0805C5.33362 14.2312 6.99703 13.7856 8.64413 13.8204C10.2929 13.8729 11.9115 14.4041 13.2909 15.3113C14.6792 16.2115 15.8332 17.4502 16.7415 18.8405C16.8496 19.0062 16.8146 19.2353 16.6641 19.3538C16.5249 19.4625 16.3345 19.4376 16.2214 19.3039Z" fill="white" fillOpacity="1" />
                  </g>
                  <defs>
                    <clipPath id="clip0_528_9387">
                      <rect width="17.5" height="20" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>

              </button>

            </div>
          </div>
        </div>
      </header>
   
      <section className='bg-slate sm:max-w-full pt-7'>
      <div className='mx-auto container text-right'>
          <span className='text-primary/70 font-normal underline cursor-pointer hover:text-danger mr-2 xl:mr-0'
          onClick={handleSkip}
          >Skip this page</span>
        </div>
        </section>


      <section className='bg-slate sm:max-w-full pt-7'>
        <div className='mx-auto container'>

{/* Xl Screen Begin */}
          <div className="bg-cover bg-[url('../public/img/landing-page-banner.jpg')] hidden w-full xl:flex items-center">
          <div className='w-full  my-52'>
          <div className='text-right mx-16 leading-tight text-primary/60 text-img-heading 
          text-6xl
           font-extralight tracking-tight'>
          Your preferred date,<br /> 
          at the best rates!
          </div>
          <div className='text-right mr-16 ml-auto mt-9'>
          <button
            onClick={handleLogin}
  
  className='text-white  text-md font-bold py-2 md:py-2.5 px-28 rounded 
    bg-danger hover:bg-danger/80
     inline-block
 '
>
Book Now
</button>
          </div>
          </div>
          </div>
{/* Xl Screen End */}
{/* Large Screen Begin */}
<div className="bg-cover bg-[url('../public/img/landing-lg.jpg')] hidden w-full lg:flex xl:hidden items-center">
          <div className='w-full  my-32'>
          <div className='text-right mx-16 leading-tight text-primary/60 text-img-heading 
          text-4xl
           font-extralight tracking-tight'>
          Your preferred date,<br /> 
          at the best rates!
          </div>
          <div className='text-right mr-16 ml-auto mt-6'>
          <button
            onClick={handleLogin}
  
  className='text-white  text-md font-bold py-2  px-20 rounded 
    bg-danger hover:bg-danger/80
     inline-block'
>
Book Now
</button>
          </div>
          </div>
          </div>
{/* Large Screen End */}

{/* Medium Screen Begin */}
<div className="bg-cover bg-[url('../public/img/landing-md.jpg')]  hidden w-full md:flex lg:hidden items-center">
          <div className='w-full  my-20'>
          <div className='text-right mx-16 leading-tight text-primary/60 text-img-heading 
          text-3xl
           font-extralight tracking-tight'>
          Your preferred date,<br /> 
          at the best rates!
          </div>
          <div className='text-right mr-16 ml-auto mt-6'>
          <button
            onClick={handleLogin}
  
  className='text-white  text-md font-bold py-2  px-20 rounded 
    bg-danger hover:bg-danger/80
     inline-block'
>
Book Now
</button>
          </div>
          </div>
          </div>
{/* Medium Screen End */}

{/* Small Screen Begin */}
<div className="bg-cover bg-[url('../public/img/landing-sm.jpg')]  w-full
flex md:hidden items-center">
          <div className='w-full  my-16'>
          <div className='text-right mx-16 leading-tight text-primary/60 text-img-heading 
          text-2xl
           font-extralight tracking-tight'>
          Your preferred date,<br /> 
          at the best rates!
          </div>
          <div className='text-right mr-16 ml-auto mt-6'>
          <button
            onClick={handleLogin}
  
  className='text-white text-sm  text-md font-normal py-2  px-10 rounded 
    bg-danger hover:bg-danger/80
     inline-block'
>
Book Now
</button>
          </div>
          </div>
          </div>
{/* Small Screen End */}
          
        </div>
      </section>

      <section className='bg-slate sm:max-w-full pt-7 pb-7'>

        <div className='container mx-auto bg-white w-full rounded  p-4  shadow-xl'>
        <SiteUsageComponent />
        </div>
        <div className='container mx-auto bg-white w-full rounded  p-4 mt-7 shadow-xl'>
        <Term readMoreAvailable={true} />

        </div>


      </section>




    </div>

  )
}

export default LandingPage