import React from 'react'

function SiteUsageComponent(){
    return (
        <div className='w-full'>
           <div className="text-2xl font-semibold text-left">
            Site Usage</div>
          <div className=" text-left w-full  pt-2  mx-auto text-xs">
            Booking your AC Quick Disinfection (QD) service with Saniservice is a breeze! Here's how to do it in a snap:
          </div>
          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
            Quick Steps to Fresh Air
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                1. Head over to https://bookings.saniservice.com and hit that Login button in the top right corner.
              </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                2. Pop in your mobile number and wait for the magic OTP to arrive on your phone.
              </label></div>

            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                3. Once you're in, you'll see a calendar that's all yours to play with. Pick a date that works for you and choose a time slot that fits your schedule.
              </label></div>

            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                4. Ready to seal the deal? Select your favorite payment method - Visa or Apple Pay - and hit "Confirm and Pay Now."
              </label></div>


          </div>
          <div className=" text-left w-full  pt-2  mx-auto text-xs">
            And voilà! You're all set for a refreshing AC experience. It's that simple to breathe easier with Saniservice!
          </div>
   </div>
    )
}

export default SiteUsageComponent