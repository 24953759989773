import React, { useState, useEffect } from "react";
import Loader from "../Loader";

const PropertyList = (props) => {
  const [selectedProperty, setSelectedProperty] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorTimer, setErrorTimer] = useState(null);

  const handleSelectProperty = (propertyId) => {
    if (!selectedProperty) {
      setError("Please select a property");
      setErrorTimer(
        setTimeout(() => {
          setError(null);
        }, 1500)
      );
    } else {
      setSelectedProperty(propertyId);
      setIsOpen(false);
      props.handlePropertyChange(propertyId);
    }
  };

  useEffect(() => {
    return () => {
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
    };
  }, [errorTimer]);

  const handleCloseButton = () => {
    setIsOpen(false);
    setSelectedProperty(undefined);
    props.setVisible(false);
  };

  useEffect(() => {
    setSelectedProperty(undefined);
  }, [props.selectedPropertyId]);

  useEffect(() => {
    if (props.visible) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.body.style.overflow = 'hidden';
      setLoading(false);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [props.visible]);

  if (!props.visible) {
    return null;
  }
   

  return (
    <div>
            <div className="w-full flex justify-end mb-3">
        <button onClick={handleCloseButton}>
          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333" />
          </svg>
        </button>
      </div>
      {loading && (
        <div className="w-full flex justify-center mb-3">
          <Loader fullHeight={false} />
        </div>
      )}
      <div>
      <p className="md:text-2xl text-md font-light mb-2">{props.clientDetails?.client_name},</p>
      <p className="md:text-sm text-xs font-light">Please select the property for next AC quarterly service below:</p>
      <div className="flex flex-col w-full mb-4 mt-4">
  <button
    className="w-full p-2 md:text-sm text-xs text-primary bg-white hover:bg-slate border border-secondary rounded text-center flex justify-between"
    onClick={() => setIsOpen(!isOpen)}
  >
    {selectedProperty ? (
      <span className="w-full truncate">
        {Object.entries(props.properties).find(([id]) => id === selectedProperty)[1]}
      </span>
    ) : (
      <span className="text-center text-gray-400 md:text-sm text-xs font-normal font-sf-pro-display leading-tight">Choose Property..</span>
    )}
    <svg
      className="fill-current w-4 h-4"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  </button>
  {isOpen && (
    <ul
      className={`w-full md:text-sm text-xs text-primary border-t border-x border-secondary rounded ${isOpen ? "block" : "hidden"}`}
      onClick={(e) => e.stopPropagation()}
    >
      {Object.entries(props.properties)
        .filter(([propertyId, propertyAddress]) => !propertyId.includes(props.selectedPropertyId))
        .map(([propertyId, propertyAddress]) => (
          <li
            key={propertyId}
            className="w-full p-2 md:text-sm text-xs cursor-pointer text-primary hover:bg-slate text-center truncate border-b"
            onClick={() => {
              setSelectedProperty(propertyId);
              setIsOpen(false);
            }}
          >
            {propertyAddress}
          </li>
        ))}
    </ul>
        )}
      </div>
      {error && (
        <div className="text-red-500 md:text-sm text-xs font-normal font-['SF Pro Display'] leading-tight mb-2">{error}</div>
      )}
      <div className="flex justify-center">
  <div className="w-full py-2 rounded font-bold bg-danger hover:bg-danger/80 flex justify-center items-center cursor-pointer"
  onClick={() => handleSelectProperty(selectedProperty)}>
    <div className="text-white md:text-md text-sm font-bold font-['SF Pro Display'] leading-tight">Submit</div>
  </div>
</div>
</div>
    </div>
  );
};
 
export default PropertyList;