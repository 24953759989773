import React, { useEffect, useState } from 'react';

const TablePagination = (props) => {

  const totalPages = Math.ceil(props.totalRecords/props.perPage);
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const paginationNumbers = [];

  let startPage, endPage;


  if (totalPages <= 5) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }


  for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
  }


  const prev = () => {
    if (currentPage === 1) return;
        setCurrentPage(currentPage - 1);
        props.fetchCustomers(currentPage - 1);
  };
  const next = () => {
    if (currentPage === totalPages) return;
        setCurrentPage(currentPage + 1);
        props.fetchCustomers(currentPage + 1);
  };

  const handleClickPage =(page_no, key) => {
    setCurrentPage(page_no);
    props.fetchCustomers(page_no);
  }
  const setPerPage =(per_page) => {
    setCurrentPage(1);
    props.setPerPage(per_page);
    
  }
 
  return (
   <div>
<div className="flex items-center justify-between  px-4 py-3 ">
    <div className="flex flex-1 justify-between md:hidden">
      <a  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary/10 cursor-pointer"
        onClick={prev}>Previous</a>
      <a  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary/10 cursor-pointer"
      onClick={next}>Next</a>
    </div>
    <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
      <div className='flex items-center gap-4'>
      <select className="border border-gray-300 rounded" 
      
      onChange={(e) => {
        setPerPage(e.target.value);
        
      }}
      defaultValue={props.perPage}
      title="Page Size">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        
        
        </select>
        <p className="text-sm text-gray-700">
          Showing
        
          <span className="font-bold px-1">{
          (currentPage * props.perPage - props.perPage) > 0 ? 
          currentPage * props.perPage - props.perPage : 1
          }</span>
        
          to
         
          <span className="font-bold px-1">{currentPage * props.perPage }</span>
        
          of
          
          <span className="font-bold px-1">{props.totalRecords}</span>
         
          results
        </p>
      </div>
      <div>
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <a 
          
          onClick={() => {
            handleClickPage(1)
          }}
          
          className="relative inline-flex items-center rounded-l-md px-2 py-2 cursor-pointer text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary/10 focus:z-20 focus:outline-offset-0">
            <span className="sr-only">Last</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
</svg>

          </a>
          <a 
          onClick={prev}
className="relative inline-flex items-center  px-2 py-2 cursor-pointer text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary/10 focus:z-20 focus:outline-offset-0">
     <span className="sr-only">Previous</span>
     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
</svg>

 </a>

          {paginationNumbers.map((pageNumber, key) => (
        
        <a key={key}

        onClick={() => {
          handleClickPage(pageNumber, key)
        }}

        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer
         ring-1 ring-inset ring-gray-300 
         ${currentPage === pageNumber ? 'bg-danger text-white': 'hover:bg-primary/10  text-primary/80'}
         focus:z-20 focus:outline-offset-0`}>
          {pageNumber}
         </a>
         



      ))}
        
          <a onClick={next}

        className="relative inline-flex items-center  px-2 py-2  ring-1 ring-inset ring-gray-300 hover:bg-primary/10 focus:z-20 focus:outline-offset-0 cursor-pointer">
           <span className="sr-only">Next</span>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>


         </a>
          
          <a 
          onClick={() => {
            handleClickPage(totalPages)
          }}
           className="relative inline-flex items-center rounded-r-md px-2 py-2  ring-1 ring-inset ring-gray-300 hover:bg-primary/10 focus:z-20 focus:outline-offset-0 cursor-pointer">
            <span className="sr-only">Last</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="h-4 w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
</svg>

          </a>
        </nav>
      </div>
    </div>
  </div>
   </div>
  );
};

export default TablePagination;