import React, { useState, useRef, useEffect } from 'react';
import Loader from "../Loader";
import dayjs from 'dayjs';
import ErpAxios from '../../api/ErpAxios';
const Confirm = (props) => {
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const BASE_URL = '/service-booking/online/payment-status';
    const handleCloseButton = (e) => {
        e.preventDefault();
        props.goBackToHomePage();
    }
    const fetchData = async () => {
        try {
          setResult(null);
          setError(false);
          setLoading(true);
          await ErpAxios.get(BASE_URL+"?reference_uuid="+props.paymentReference)
            .then(response => {
              setResult(eval(response.data));
              setLoading(false);
              setError(false);
              console.log('Fetched data:', response.data);
            })
            .catch((error) => {
              console.log(error);
              setResult(null);
              setLoading(false);
              setError(true);
            })
        } catch (error) {
          console.log(error);
        }
    
      };
      useEffect(() => {
        if(props.paymentReference){
            fetchData();
        }
       
      }, [props.paymentReference]);
    return (
        <div>

            <div className="w-full flex justify-end mb-3">
                <button onClick={handleCloseButton}>
                <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"
                    width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333" />
                </svg>
                </button>
            </div>
            {(loading) && <div className="w-full flex justify-center mb-3"><Loader fullHeight={false} /></div>}
          {result && result.InvoiceStatus  &&  (<div>
            <p className="md:text-2xl text-xl font-light">Dear {result?.client_name}</p>

            {result && result.InvoiceStatus === "Paid"&& (
                <div>
                <div className="md:py-8 py-5 text-center flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" width="55" height="40" viewBox="0 0 55 40" fill="none">
<path d="M54.9334 2.10089L18.4624 37.9541L16.3755 40L14.3418 37.8991L0.0703125 23.1661L2.15868 21.1187L16.4287 35.8532L52.8997 0L54.9334 2.10089Z" fill="#EE5253"/>
<path d="M18.2871 37.7758L16.3802 39.6454L14.5214 37.7252L14.5214 37.7252L0.42278 23.1706L2.15408 21.4733L16.2491 36.0271L16.4243 36.208L16.6039 36.0315L52.8953 0.354896L54.5812 2.09653L18.2874 37.7756L18.2871 37.7758Z" stroke="#333333" strokeOpacity="0.08" strokeWidth="0.5"/>
</svg>

                </div>
                {result.total_amount > 0  && (
                    <p className="uppercase md:text-3xl text-2xl font-extralight pb-7 text-center w-full">Payment Successful</p> 
                )}
                 {result.total_amount <= 0  && (
                    <p className="uppercase md:text-3xl text-2xl font-extralight pb-7 text-center w-full">Service Confirmed</p> 
                )}

                </div>
                

                )}
                {result && result.InvoiceStatus !== "Paid"  && (
                    <div>
                <div className="md:py-8 py-5 text-center flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" width="55" height="40" viewBox="0 0 55 40" fill="none">
  <path d="M40.7986 2.10089L4.32764 37.9541L2.24075 40L0.207031 37.8991H0.199219L2.45778 35.8532L38.7649 0L40.7986 2.10089Z" fill="#EE5253"/>
  <path d="M4.15238 37.7758L2.24539 39.6454L0.562743 37.9071L2.62562 36.0385L2.62578 36.0386L2.63344 36.0311L38.7609 0.355305L40.4465 2.09653L4.15263 37.7756L4.15238 37.7758Z" stroke="#333333" strokeOpacity="0.08" strokeWidth="0.5"/>
  <path d="M0.197464 2.10089L36.6685 37.9541L38.7553 40L40.7891 37.8991H40.7969L38.5383 35.8532L2.23119 0L0.197464 2.10089Z" fill="#EE5253"/>
  <path d="M36.8437 37.7758L38.7507 39.6454L40.4334 37.9071L38.3705 36.0385L38.3703 36.0386L38.3627 36.0311L2.23519 0.355305L0.549637 2.09653L36.8435 37.7756L36.8437 37.7758Z" stroke="#333333" strokeOpacity="0.08" strokeWidth="0.5"/>
</svg>
    
</div>
    
                    <p className="uppercase md:text-3xl text-2xl font-extralight pb-7 text-center w-full">Payment Failed</p></div>
                    )}

                    
                <div className="gap-2.5 flex flex-col">
                <p className="text-center w-full text-xs font-normal">Transaction ID: {result?.transaction_no}</p>
                <p className="text-center w-full text-xs font-light">Reference Number:</p>
                <p className="text-center w-full text-xs font-medium">{result?.reference_no}</p>
                <p className="text-center w-full text-xs font-light">We at Saniservice assure you of our best services at all times.</p>
                </div>

                <div className="w-full border-t py-2  border-b border-primary/30">
                    <div className="table align-middle w-full">
                        <div className="table-row text-sm font-normal text-primary">
                            <div className="table-cell w-8/12">
                                SERVICE DATE
                            </div>
                            <div className="table-cell w-4/12 text-right">
                                AMOUNT
                            </div>
                        </div>
                        <div className="table-row text-xs font-normal text-primary">
                            <div className="table-cell w-8/12">
                         
                            {result.previous_service_date && (
                                <div>
                                   
                                    <div className='font-medium pb-1'>
                                            {dayjs(result.service_date).format('MMMM D, YYYY')}
                                    </div>
                                    <div className='line-through text-primary/50 decoration-primary/50'>
                                            {dayjs(result.previous_service_date).format('MMMM D, YYYY')}
                                    </div>
                                </div>
                            )}
                             {!result.previous_service_date && (
                                <div>
                                    {dayjs(result.service_date).format('MMMM D, YYYY')}
                                </div>
                            )}




                            </div>
                            <div className="table-cell w-4/12 text-right">
                           

                            {result.previous_service_date && (
                                <div>
                                  
                                    <div className='font-medium pb-1'>
                                    {result.service_amount}
                                    </div>
                                    <div className='line-through text-primary/50 decoration-primary/50'>
                                    {result.previous_service_amount}
                                    </div>
                                </div>
                            )}
                            {!result.previous_service_date && (
                                <div>{result.service_amount}</div>
                            )}  




                            </div>
                        </div>

                    </div>

                </div>
                <div className="w-full py-2  border-b border-primary/30">
                <div className="table align-middle w-full">
                    <div className="table-row text-xs font-normal  text-primary">
                        <div className="table-cell w-9/12 pb-1  pr-1 text-right">SUBTOTAL</div>
                        <div className="table-cell w-3/12  text-right">{result?.service_amount}</div>
                    </div>
                        <div className="table-row text-xs font-normal text-primary">
                            <div className="table-cell w-9/12  pr-1 pb-1 text-right">VAT(5%)</div>
                            <div className="table-cell w-3/12 text-right">{result?.vat_amount}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full py-2 mb-3 border-b border-primary/30">
                <div className="table align-middle w-full">
                    <div className="table-row md:text-sm text-xs font-normal  text-danger">
                        <div className="table-cell w-9/12  pr-1 text-right">TOTAL AMOUNT</div>
                        <div className="table-cell w-3/12  text-right">{result?.total_amount}</div>
                    </div>
                      
                    </div>
                </div>

               
             <div className="text-primary/50 text-xxs py-5 text-justify">
             Terms and Conditions: All prices are shown in AED. SANISERVICE LLC, is located at  Saniservice Warehouse, Al Quoz Industrial Area 3, Behind Dubai Driving Center, Next to Porsche Service Center.*

             </div>
              
              

                <button className="w-full bg-danger text-white py-2 text-sm rounded font-bold hover:bg-danger/90"
                    onClick={handleCloseButton}
                >Go to Booking</button>





            </div>)
}
        </div>
    );
}
export default Confirm;