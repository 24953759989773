import React from "react";
function Nav(props) {
    const dateFormat = "MMMM YYYY";
    const prevMonth = () => {
        const minus = props.currentMonth.subtract(1, "month");
        props.onPrevious(minus)
    };
    const nextMonth = () => {
        const plus = props.currentMonth.add(1, "month");
        props.onNext(plus)
    };

    return (
       
            <div className="flex justify-center py-8">
                <button className="text-primary hover:text-primary/50" onClick={() => prevMonth()}>
                    

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
</svg>




                </button>
                <h2 className="mx-10 md:text-2xl text-xl font-normal">
                    {props.currentMonth.format(dateFormat)}
                </h2>
                <button className="text-primary hover:text-primary/50" onClick={() => nextMonth()}>
                  

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>



                </button>
            </div>
        
    )
}
export default Nav;
