import React, { useState } from 'react'
function Term(props){
    const [isReadMore, setIsReadMore] = useState(true);
    const [readMoreAvailable, setReadMoreAvailable] = useState(props.readMoreAvailable);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    
    return (
        <div className='w-full'>
         <div className="text-2xl font-semibold text-left">
            Terms and Conditions for Saniservice Quarterly Disinfection Booking Website</div>
          <div className=" text-left w-full  pt-2  mx-auto text-xs">
            By using the Saniservice Quarterly Disinfection Booking website, you agree to the following terms and conditions:
            {readMoreAvailable && isReadMore  && (<span  onClick={toggleReadMore} 
            className='text-danger ml-2 cursor-pointer'>read more...</span>)}
          </div>

          { ((readMoreAvailable && !isReadMore) || !readMoreAvailable) &&
          <div>
          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
            Booking and Payment
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                1. All selected dates for QD disinfection services must be paid in advance to confirm your booking.
              </label></div>

            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                2. Payment can be made through the secure payment gateway provided on the website.
              </label></div>

            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
                3. Prices are subject to change without prior notice. The price displayed at the time of booking will be the final price for that particular date.
              </label></div>
          </div>


          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Cancellations and Rebooking
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. If you cancel a booking that has already been paid for, the full amount will be credited back to your online "wallet" associated with your account on the Saniservice QD Booking website.
               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              2. The credited amount in your wallet can be used to book disinfection services for a different date.
               </label></div>
          </div>


            <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Cancellations and Rebooking
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. If you cancel a booking that has already been paid for, the full amount will be credited back to your online "wallet" associated with your account on the Saniservice QD Booking website.
               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              2. The credited amount in your wallet can be used to book disinfection services for a different date.
               </label></div>

               <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              3. Price Adjustments:
              <br />
              - If the price of the new booking is higher than the previous one, you will be charged for the difference in pricing.
              <br />
              - If the price of the new booking is lower, the new price will be deducted from your wallet, and the remainder will be left in your account for future bookings.
               </label></div>

               <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              4. Rebooking Limit: <br />
              - You are allowed to rebook only once without incurring additional charges. <br />
   - Any subsequent rebooking after the first one will be subject to a rebooking fee of AED 50.00.
               </label></div>

          </div>


          
          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Wallet Usage
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. Your online wallet balance can only be used for booking Saniservice disinfection services.  Request to reimburse your wallet amount to cash should be made via an official request to:  finance@saniservice.om
               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
             2. Wallet balances do not expire and can be used for future bookings at any time.
               </label></div>
          </div>

          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Service Delivery
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. Saniservice reserves the right to reschedule appointments in case of unforeseen circumstances or emergencies.

               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
            2. The client is responsible for providing accurate information regarding the QD Service and ensuring access to the premises on the scheduled date.
               </label></div>
          </div>

          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Liability
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. Saniservice is not liable for any damage to property that may occur during the normal course of disinfection services. (More info on our Main Saniservice Terms and Conditions)
               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              2. The client is responsible for removing or securing any valuable or fragile items before the QD disinfection service.  (More info on our Main Saniservice Terms and Conditions)
               </label></div>
          </div>


          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Privacy and Data Protection
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. Personal information provided during the booking process will be handled in accordance with our Privacy Policy.
               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              2. By using this website, you consent to the collection and use of your information as described in the Privacy Policy.
               </label></div>
          </div>

          <div className=" text-left w-full  pt-2 mt-2  mx-auto text-xl font-normal">
          Modifications to Terms and Conditions
          </div>
          <div className='mt-5 mb-7 gap-1.5 flex flex-col'>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              1. Saniservice reserves the right to modify these terms and conditions at any time without prior notice.
               </label></div>
            <div class="bg-primary-05 rounded hover:bg-primary/10">
              <label class="flex items-center justify-between space-x-2 py-1.5 px-2.5 text-xs">
              2. Continued use of the website after any modifications indicates your acceptance of the updated terms and conditions.
               </label></div>
          </div>
          </div>
}
{readMoreAvailable && !isReadMore  && (<span  onClick={toggleReadMore} 
            className='text-danger text-xs ml-2 cursor-pointer'>show less...</span>)}


   </div>
    )
}

export default Term