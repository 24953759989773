import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import erpAxios from '../../api/ErpAxios';
const app_env = process.env.REACT_APP_ENV;
const developer_mobile = process.env.DEVELOPER_MOBILE;
export default function Login({ setToken, setAccount }) {
  const [accountId, setAccountId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const otpInputlength = 6;
  const [otp, setOtp] = useState(Array(otpInputlength).fill(''));
  const otpInputs = useRef([]);
  const LOGIN_API_URL = '/service-booking/login';
  const [error, setError] = useState('');
  
  const handleMobileInputChange = (val) => {
    const re = /^[0-9\b]+$/;
    if (val === '' || re.test(val)) {
       setMobileNumber(val)}
    
  };

  const COUNTDOWN = 120; 
  const [countdown, setCountdown] = useState(COUNTDOWN); // 2 minutes

  const sendOtp = async e => {
    try {
      const response = await erpAxios.post(LOGIN_API_URL, {
        mobile: mobileNumber,
        type: 'otp',
        app_env:  app_env,
        dev_mobile: developer_mobile
      });
      if (response.data.status === true) {
        setAccountId(response.data.account_id);
        setShowOtpInput(true);
        setCountdown(COUNTDOWN); // Reset the countdown
        setError('');
        //console.log(response.data.otp_code)
      } else {
        setError(response.data.message);
        console.log(response.data);
      }
    } catch (error) {
      setError('Failed to send OTP');
      console.log(error);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setOtp(Array(otpInputlength).fill('')); // Clear the OTP input field
    sendOtp();
  };
  
  

  const handleOtpInputChange = (e, index) => {
    const { value } = e.target;
    // Only allow single digit input
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Move focus to the next input
      if (index < otpInputlength - 1) {
        otpInputs.current[index + 1].focus();
      }
      
      // If all OTP digits are filled, send the OTP verification automatically
    if (newOtp.every((digit) => digit !== '')) {
      handleOtpInputSubmit(newOtp);
    }
    }

    // Move focus to previous input on backspace
    if (value === '' && index > 0) {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleOtpInputKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      for (let i = index; i >= 0; i--) {
        if (otp[i] !== '') {
        const newOtp = [...otp];
        newOtp[i] = '';
        setOtp(newOtp);
        otpInputs.current[i].focus();
        break;
      } else if (i > 0) {
        otpInputs.current[i - 1].focus();
        }
      }
    }
  };

  const handleMobileNumberSubmit = async (e) => {
    e.preventDefault();
    sendOtp();
  };


  const handleOtpInputSubmit = async (newOtp) => {
try {
  await erpAxios.post(LOGIN_API_URL, {
    otp_code: newOtp,
    type: 'verify-otp',
    account_id: accountId 
  })
    .then(response => {
      if(response.data.status === true){
        setToken(response.data.token);
        setAccount(eval(response.data.account));
      }else{
        setError(response.data.message);
        console.log(response.data);
        setTimeout(() => {
          setError(null);
        }, 3000); // Remove error message after 3 seconds
      }
    })
    .catch((error) => {
      console.log(error);
    })
      

} catch (error) {
console.log(error);
}
}

  const handleOtpCopyPaste = (e, index) => {
    e.preventDefault();
    const pasteValue = e.clipboardData.getData('Text');
    const otpArray = pasteValue.split('');
    const newOtp = [...otp];

    otpArray.forEach((digit, i) => {
      if (index + i < otp.length) {
        newOtp[index + i ] = digit;
      }
    });

    setOtp(newOtp);
  if (newOtp.every((digit) => digit !== '')) {
    handleOtpInputSubmit(newOtp);
  }
};

  useEffect(() => {
    let intervalId;
    if (showOtpInput && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [showOtpInput, countdown]);


  return (
    <div className="w-full h-screen flex">
      <div className='w-full mx-auto flex login-bg bg-cover'>

        <div className='hidden md:w-8/12 bg-primary/50 backdrop-blur-sm md:flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" className='hidden md:block text-center mx-auto' width="214" height="60" viewBox="0 0 214 60" fill="none">
            <g clipPath="url(#clip0_505_4962)">
              <path d="M0.857935 39.8905V36.0024H23.7386C25.9296 36.0024 27.0316 35.1959 27.0316 33.5764V30.2522C27.0316 28.6327 25.9361 27.8197 23.7386 27.8197H8.26397C6.76833 27.8197 5.49573 27.6623 4.45272 27.3542C3.40315 27.046 2.55037 26.6067 1.89439 26.0428C1.23185 25.4855 0.752978 24.8167 0.457787 24.043C0.156035 23.2694 0.0117188 22.4104 0.0117188 21.4663V19.932C0.0117188 18.9879 0.162595 18.1289 0.457787 17.3552C0.759538 16.5816 1.23841 15.9193 1.89439 15.3555C2.55693 14.7982 3.40971 14.3589 4.45272 14.0441C5.50229 13.736 6.76833 13.5786 8.26397 13.5786H30.5477V17.4667H8.26397C6.07299 17.4667 4.97094 18.2797 4.97094 19.8992V21.5056C4.97094 23.1251 6.06643 23.9381 8.26397 23.9381H23.7451C25.2408 23.9381 26.5134 24.0955 27.5564 24.4037C28.606 24.7184 29.4587 25.1511 30.1147 25.715C30.7773 26.2723 31.2561 26.9411 31.5513 27.7148C31.8531 28.4885 31.9974 29.3474 31.9974 30.2915V33.5436C31.9974 34.4878 31.8465 35.3533 31.5513 36.1204C31.2496 36.8941 30.7707 37.5563 30.1147 38.1202C29.4522 38.6775 28.5994 39.1168 27.5564 39.425C26.5068 39.7397 25.2408 39.8905 23.7451 39.8905H0.857935Z" fill="white" />
              <path d="M95.5095 39.8905L81.9373 18.6994L68.142 39.8905H62.6055L80.1071 13.5786H83.7346L101.118 39.8905H95.5095Z" fill="white" />
              <path d="M208.578 39.8905V13.5786H213.55V39.8905H208.578Z" fill="white" />
              <path d="M134.176 39.8712V13.5986H138.085L161.675 38.7303L138.741 19.657L138.768 39.8712H134.176Z" fill="white" />
              <path d="M168.949 13.625V39.8975H165.046L141.457 14.7659L164.39 33.8392L164.357 13.625H168.949Z" fill="white" />
              <path d="M208.574 11.3955H213.546V8.72692L193.375 0L208.561 9.13998L208.574 11.3955Z" fill="white" />
              <path d="M1.23984 58.02C1.90895 58.4265 2.8798 58.774 3.90313 58.774C5.42501 58.774 6.31058 57.9741 6.31058 56.807C6.31058 55.7317 5.69396 55.1154 4.13928 54.5187C2.26318 53.8499 1.09553 52.8796 1.09553 51.2535C1.09553 49.4635 2.58461 48.126 4.82151 48.126C6.00227 48.126 6.85505 48.4014 7.36672 48.6898L6.96001 49.9028C6.5861 49.6996 5.81204 49.3586 4.77559 49.3586C3.20123 49.3586 2.60429 50.2962 2.60429 51.083C2.60429 52.1583 3.30619 52.6894 4.89366 53.3057C6.84193 54.0598 7.83246 54.9974 7.83246 56.689C7.83246 58.4658 6.51394 60.0001 3.79817 60.0001C2.68956 60.0001 1.476 59.6788 0.859375 59.2657L1.23328 58.02H1.23984Z" fill="white" />
              <path d="M41.804 54.4333H37.3302V58.5836H42.3222V59.8294H35.8477V48.3159H42.0664V49.5617H37.3367V53.2006H41.8105V54.4333H41.804Z" fill="white" />
              <path d="M70.2097 48.4733C70.9641 48.3225 72.0399 48.2373 73.0632 48.2373C74.6507 48.2373 75.674 48.5258 76.3956 49.1749C76.9794 49.6863 77.3008 50.4731 77.3008 51.3583C77.3008 52.8794 76.3431 53.8826 75.1296 54.2957V54.3481C76.0151 54.6563 76.5465 55.4759 76.822 56.6692C77.1959 58.2756 77.4714 59.3836 77.7076 59.8295H76.1726C75.9823 59.5016 75.7265 58.5116 75.4051 57.0822C75.064 55.4955 74.4473 54.8989 73.096 54.8464H71.6922V59.836H70.2031V48.4799L70.2097 48.4733ZM71.6922 53.7187H73.2141C74.8016 53.7187 75.8118 52.8466 75.8118 51.5353C75.8118 50.0469 74.736 49.3978 73.1616 49.3847C72.4466 49.3847 71.9284 49.4503 71.6922 49.5224V53.7252V53.7187Z" fill="white" />
              <path d="M108.165 59.8294L104.406 48.3159H106.013L107.804 53.9874C108.303 55.5414 108.729 56.9445 109.037 58.2886H109.07C109.398 56.9576 109.871 55.502 110.389 54.0005L112.337 48.3159H113.925L109.805 59.8294H108.165Z" fill="white" />
              <path d="M142.622 48.3159V59.8294H141.133V48.3159H142.622Z" fill="white" />
              <path d="M179.231 59.4562C178.687 59.7315 177.591 60.0004 176.187 60.0004C172.94 60.0004 170.5 57.9481 170.5 54.178C170.5 50.408 172.94 48.1328 176.515 48.1328C177.952 48.1328 178.857 48.441 179.251 48.6442L178.89 49.8572C178.326 49.5818 177.526 49.3786 176.568 49.3786C173.865 49.3786 172.074 51.103 172.074 54.1256C172.074 56.945 173.695 58.7546 176.502 58.7546C177.407 58.7546 178.332 58.5645 178.929 58.276L179.238 59.4562H179.231Z" fill="white" />
              <path d="M213.027 54.4333H208.553V58.5836H213.545V59.8294H207.07V48.3159H213.289V49.5617H208.559V53.2006H213.033V54.4333H213.027Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_505_4962">
                <rect width="213.548" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="w-full md:w-1/2 bg-white flex items-center p-8">
        <div className='max-w-sm mx-auto w-sm'>


            {!showOtpInput ? (
              <div className='w-full'>

                <h1 className='font-normal text-4xl'>Hello! 👋</h1>
                <h2 className='text-xl font-normal my-7'>Welcome to Saniservice</h2>

                <form onSubmit={handleMobileNumberSubmit} className='text-center'>

                  <input
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => handleMobileInputChange(e.target.value)}

                    placeholder="Mobile Number"
                    className='w-full py-2.5 px-0 mt-2.5  border-b-1 border-t-0 border-x-0 border-primary/40 focus:border-primary/80 focus:bg-primary-05 focus:ring-0'
                  />
                  {error && <p className='text-danger text-sm text-left mt-1 capitalize'>{error}</p> }
                  <button type="submit" className='w-full mt-10 h-10 text-center bg-danger rounded text-sm font-bold text-white tracking-wide'>
                    Send OTP
                  </button>
                </form>
              </div>

            ) : (
              <div className='w-full'>

                <h1 className='font-normal text-4xl'>Enter OTP</h1>
                <h2 className='text-base font-normal text-primary/80 my-7'>Enter 6-digit code sent to your Phone No.</h2>
                <form onSubmit={handleOtpInputSubmit}>

                  <div className="flex justify-center gap-2">
                    {otp.map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => handleOtpInputChange(e, index)}
                        onKeyDown={(e) => handleOtpInputKeyDown(e, index)}
                        onPaste={(e) => handleOtpCopyPaste(e, index)}
                        ref={(el) => (otpInputs.current[index] = el)}
                        className='w-10 h-10 text-center border border-primary/50 hover:border-primary focus:ring-0 
focus:ring-offset-0 rounded'
                      />
                    ))}
                  </div>
                  {error && <p className='text-danger text-sm text-left mt-1 capitalize'>{error}</p> }
                 
                  <div className='text-left my-4 text-xs text-primary/50'>
                  {countdown > 0? (
                  <span>Resend OTP in {countdown}s</span>
                  ) : (
                  <button onClick={handleResendOtp} className='w-full h-10 mt-2 text-center bg-danger rounded text-sm font-bold text-white tracking-wide'>
                    Resend OTP
                  </button>
                )}
                  </div>
                </form>

              </div>
            )}

          </div>

        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};