import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
export default function Day(props) {
  const [arrayOfDays, setArrayOfDays] = useState([]);
  const handleDayClick = (selecteddate, availabledate) => {
    if(availabledate){
        props.onSelect(selecteddate)  
    }
};

  const getAllDays = () => {
		let currentDate = props.currentMonth.startOf("month").weekday(0);
		const nextMonth = props.currentMonth.add(1, "month").month();

		let allDates = [];
		let weekDates = [];

		let weekCounter = 1;

		while (currentDate.weekday(0).toObject().months !== nextMonth) {
           const formated = formateDateObject(currentDate);

			weekDates.push(formated);

			if (weekCounter === 7) {
				allDates.push({ dates: weekDates });
				weekDates = [];
				weekCounter = 0;
			}

			weekCounter++;
			currentDate = currentDate.add(1, "day");
		}

		setArrayOfDays(allDates);
	};

	useEffect(() => {
		getAllDays();
	}, [props.currentMonth]);


  const formateDateObject = date => {
        const clonedObject = { ...date.toObject() };

        const formatedObject = {
             day: clonedObject.date,
             day_title: date.format('ddd'),
             date: date.format("YYYY-MM-DD"),
            month: clonedObject.months,
            year: clonedObject.years,
            isCurrentMonth: clonedObject.months === props.currentMonth.month(),
            isCurrentDay: date.isToday(),
            isSameOrBefore: date.isSameOrBefore(dayjs()),
           // isBooked: props.bookedServices.some((service) => service[date.format("YYYY-MM-DD")]),
        };

        return formatedObject;
    };


    const rows = [];
    let days = [];
    //console.log(props.items)
   const lowestAmount = props.items && Object.keys(props.items).length > 0 ? Math.min(...Object.values(props.items).map(item => item.latest_amount)) : Infinity;
   arrayOfDays.forEach((week, index) => {
    week.dates.forEach((d, i) => {
    //const isBooked = props.bookedServices.some((service) => service[d.date]);
    const isBooked = false;     
    const upcomingBookings = props.upcomingBookings && props.upcomingBookings.data ? props.upcomingBookings.data.filter((booking) => booking.service_date === dayjs(d.date).format('YYYY-MM-DD')) : [];
            days.push(
                

                <div onClick={() => {
                    if (!isBooked) {
                        handleDayClick(d.date, props.items[d.date])}}}
                    className={`border border-gray-200 flex flex-col p-2.5 md:min-h-32  min-h-16 w-auto
                        ${!d.isCurrentMonth ? "bg-slate" : ""}
                        ${isBooked
                          ? "bg-danger cursor-not-allowed"
                          : (props.items && props.items.length !== 0 && props.items[d.date])
                          ? "hover:border-primary/50 hover:border bg-white cursor-pointer"
                          : d.isSameOrBefore || d.day_title === "Sun" || !d.isCurrentMonth
                          ? "cursor-not-allowed text-primary/20 bg-white/60"
                          : "cursor-not-allowed text-primary/20 bg-white/90"}
                        ${props.selectedDate === d.date ? "border-primary/50 border-2" : ""}
                        `
                    }
                    key={i}
                >
                   
                   <div className="w-full flex md:justify-between justify-center">
                   <p className={`md:text-sm sm:text-xs text-xs ${d.isBooked ? 'text-white' : 'text-primary'}`}>{d.day}</p>
                    </div>
                

               
                 {(props.items) && props.items.length !== 0 && (props.items[d.date]) ?
                 
                <div>
                {props.showUpcomingBookings && upcomingBookings && upcomingBookings.map((booking, index) => (
                  <div key={index} className="bg-[#f59e0b] px-1 mb-0.5 -mx-2">
                    <div className="text-white text-xs">
                      <p className="text-white text-xs">{booking.area}</p>
                      <p className="text-xs">{dayjs(`${booking.start_time} 00`, 'HH:mm:ss Z').format('h:mm A')} - {dayjs(`${booking.end_time} 00`, 'HH:mm:ss Z').format('h:mm A')}</p>
                      </div>
                      </div>
                    ))}
               <p className={`md:text-base text-xs font-bold  md:inline-block flex flex-col items-center 
                ${props.showUpcomingBookings && upcomingBookings && upcomingBookings.length > 0  ? (upcomingBookings.length > 1 ? 'md:mt-1 mt-1' : 'md:mt-4 mt-4'): 'md:my-6 mt-4' }
               `}>
                <span className={`font-light mr-1 md:inline-block hidden ${isBooked ? 'text-white' : ''}`}>AED</span>
                <span className={` ${isBooked ? 'text-white md:text-white' : ''} ${props.items[d.date].latest_amount === lowestAmount ? 'text-succes md:text-primary' : ''} `}>{props.items[d.date].latest_amount.toFixed(2)}</span>
                </p>
                {!isBooked && props.items[d.date].latest_amount === lowestAmount &&
                <p className="text-right italic text-succes md:block text-xs hidden">Lowest Price</p>
                }
                {isBooked && <p className="text-right italic bold text-xs text-white md:block hidden">Booked</p>}
                </div>
                :
                (d.isSameOrBefore || d.day_title === 'Sun') ? '' : (!d.isCurrentMonth ? '' : <div className="md:text-sm text-xs font-normal md:my-6 text-center ">SOLD OUT</div>)
                
                

        }







                </div>
            );
        });
        rows.push(
            <div className="grid grid-cols-7" key={index}>
                {days}
            </div>
        );
        days = [];
    });

    return <div className="drop-shadow-calandar">
      {rows}
      </div>;
}
