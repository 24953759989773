import React, { useEffect, useState } from 'react';
import erpAxios from '../../api/ErpAxios';

const SnoozePopup = (props) => {
  const [reason, setReason] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [reasonError, setReasonError] = useState(null);
  const [durationError, setDurationError] = useState(null);
  const [snoozeDuration, setSnoozeDuration] = useState({ value: '', unit: '' });

  const handleSnooze = () => {
    if (reason.trim() === '') {
      setReasonError('Please enter a reason');
    } else {
      setReasonError(null);
    }
  
    if (snoozeDuration.value === '') {
      setDurationError('Please select a duration');
    } else {
      setDurationError(null);
    }
  
    if (reason.trim() !== '' && snoozeDuration.value !== '') {
      let noOfDays = 0;
      switch (snoozeDuration.unit) {
        case 'days':
          noOfDays = parseInt(snoozeDuration.value, 10);
          break;
        case 'weeks':
          noOfDays = parseInt(snoozeDuration.value, 10) * 7;
          break;
        case 'months':
          noOfDays = parseInt(snoozeDuration.value, 10) * 30; // assuming 30 days per month
          break;
        default:
          console.error('Invalid unit:', snoozeDuration.unit);
          return;
      }
  
      erpAxios.post('service-booking/snooze', {
        account_id: props.accountId,
        property_id: props.propertyId,
        no_of_days: noOfDays,
        user_type: 'sales',
        user_id: props.account.user_id,
        reason: reason
      })
      .then(response => {
        console.log(
          `Snoozing client 
          ${props.property.client_name} for 
          ${snoozeDuration.value} ${snoozeDuration.unit} with reason: 
          ${reason}`
        );
        props.onClose();
        setIsOpen(false);
      })
      .catch(error => {
        console.error('Error snoozing client:', error);
      });
    }
  };


  const handleCancel = () => {
    setReason('');
    setSnoozeDuration({ value: '', unit: '' });
    props.onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; 
    }
    return () => {
      document.body.style.overflow = 'auto'; 
    };
  }, [isOpen]);


  return (
    <div className="fixed top-0 left-0 w-full h-full  bg-opacity-50 flex justify-center items-center
    
    bg-white/20 backdrop-blur-sm
    
    
    ">
      <div className=" p-4 bg-white rounded
      
      md:w-96 w-54  gap-1 shadow-2xl shadow-primary/50 md:mx-auto md:py-5 md:px-8 m-8
      
      ">
        <div className="md:w-full flex justify-end md:mb-3">
          <button onClick={handleCancel}>
            <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"
              width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333" />
            </svg>
          </button>
        </div>
        <p className="md:text-2xl text-lg font-light">Snooze Now,</p>
        <div className="mt-5">
          <p className="md:text-sm text-xs font-normal pb-4">Please enter the reason for snoozing:</p>
          <div className="table-row text-xs">
      <div className="table-cell  w-4/12 border-b py-2 px-1">
        Client:
      </div>
      <div className="table-cell border-b py-2 font-bold">
      {props.property.client_name}
      </div>
    </div>
    <div className="table-row text-xs">
      <div className="table-cell  w-4/12 border-b py-2 px-1">
        Location:
      </div>
      <div className="table-cell border-b py-2 font-bold">
      {props.property.address}
      </div>
    </div>
    <p className="md:text-sm text-xs font-normal pt-4">Reason: <span className='text-danger'>*</span></p>
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className={`w-full p-2 border rounded text-xs h-20 focus:outline-none ring-0 focus:ring-0 focus:ring-primary/20 border-1 border-primary/20 ${
              reasonError ? 'border-red-500' : 'border-primary/20'
            }`}
            placeholder='Please enter the reason...'
          />
           {reasonError && (
            <p className="text-red-500 text-sm">{reasonError}</p>
          )}
          <p className="md:text-sm text-xs font-normal pt-4">Select the snooze duration:<span className='text-danger'>*</span></p>
          <select
            value={snoozeDuration.value + ' ' + snoozeDuration.unit}
            onChange={(e) => {
              const [value, unit] = e.target.value.split(' ');
              setSnoozeDuration({ value, unit });
            }}
            className={`w-full p-2 border rounded cursor-pointer focus:outline-none ring-0 text-xs focus:ring-0 focus:ring-primary/20 border-1 ${
              durationError ? 'border-red-500' : 'border-primary/20'
            }`}
          >
            <option value="">Select duration</option>
            <option value="1 days">1 day</option>
            <option value="2 days">2 days</option>
            <option value="3 days">3 days</option>
            <option value="4 days">4 days</option>
            <option value="5 days">5 days</option>
            <option value="1 weeks">1 week</option>
            <option value="2 weeks">2 weeks</option>
            <option value="3 weeks">3 weeks</option>
            <option value="1 months">1 month</option>
            <option value="2 months">2 months</option>
            <option value="3 months">3 months</option>
          </select>
          {durationError && (
            <p className="text-red-500 text-sm">{durationError}</p>
          )}
          <div className="flex justify-end mt-5">
            <button className="w-full text-white py-2 text-sm rounded font-bold bg-danger hover:bg-danger/80" onClick={handleSnooze}>
              Snooze
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnoozePopup;