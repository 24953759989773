import axios from 'axios';

//const KEY = 'g74xJI2ZSIfLWgBcMSa144sl2Ln3HMAH';
const URL = process.env.REACT_APP_ERP_API_URL;
const REACT_APP_ENV = process.env.REACT_APP_ENV;
export default axios.create({
    baseURL: URL,
    params: {
       // app_env: REACT_APP_ENV
    }
});