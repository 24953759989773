import React, { useState, useEffect } from 'react';
import axios from '../../api/Axios';
import Loader from '../Loader'
import dayjs from "dayjs";
import SnoozePopup from './SnoozePopup';
import TablePagination from './TablePagination';
import SearchArea from './SearchArea';
import SearchName from './SearchName';
const TopRatedClients = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [properties, setProperties] = useState();
  const [searchName, setSearchName] = useState('');
  const [searchArea, setSearchArea] = useState(props.selectedArea);
  const [searchDate, setSearchDate] = useState(props.selectedDate);
  const [searchPriority, setSearchPriority] = useState(props.selectedPriority ? [props.selectedPriority.toUpperCase()] : []);
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const [showSnoozePopup, setShowSnoozePopup] = useState(false);
  const [snoozedProperty, setSnoozedProperty] = useState(null);
  const priorities = [ 'Very High', 'High', 'Medium', 'Low', 'Very Low'];
  const [currentPage, setCurrentPage] = useState(1); 
  const [perPage, setPerPage] = useState(25); 
  const [totalRecords, setTotalRecords] = useState(null); 
  const [globalAreas, setGlobalAreas] = useState(null);
  const [globalClients, setGlobalClients] = useState(null);
  const [sortBy, setSortBy] = useState('score');
  const [sortDir, setSortDir] = useState('asc');
  const [searchFromAreas, setSearchFromAreas] = useState(props.searchFromAreas);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const nextMonth = new Date();
  nextMonth.setDate(new Date().getDate() + 31);
  const minDate = tomorrow.toISOString().split('T')[0];
  const maxDate = nextMonth.toISOString().split('T')[0];

  const handleSortOrder = (col) => {
    let dir = sortDir === 'asc' ? 'desc' : 'asc';
    if (sortBy === col) {
      setSortDir(dir);
    } else {
      setSortDir(dir);
      setSortBy(col); 
    }
    fetchCustomers(searchName, searchArea, searchPriority, searchDate, currentPage, perPage, col, dir);
    console.log('SORTBY', sortBy)
    console.log('SORTDIR', sortDir)
  };


  const fetchCustomers = async (client_details, area, priority, service_date, page_no, per_page, sortBy, sortDir) => {
    try {
      
      setProperties(null);
      setTotalRecords(null);
      setGlobalAreas(null);
      setGlobalClients(null);
      setLoading(true);
      setError(false);
      setSearchFromAreas(false);
      await axios.get('/properties', {
        params: {
          client_details: client_details,
          area: area,
          priority: priority,
          service_date: service_date,
          page_no: page_no,
          per_page: per_page,
          sortBy: sortBy,
          sortDir: sortDir,
        }
      })
        .then(response => {
          console.log(priority);
      console.log('shihab');
          setProperties(eval(response.data.results));
          setTotalRecords(response.data.total_records);
          setGlobalAreas(eval(response.data.areas));
          setGlobalClients(response.data.clients);
          setLoading(false);
          setError(false);
        })
        .catch(error => {
          console.log(error);
          setProperties(null);
          setGlobalAreas(null);
          setGlobalClients(null);
          setTotalRecords(null);
          setLoading(false);
          setError(true);
        });
    } catch (error) {
      console.log(error);
    }
  };


  // useEffect(() => {
  //   if(searchFromAreas){
  //     console.log(searchFromAreas)
  //     handleAreaBasedSearch();
  //   }else{
  //     fetchCustomers('', '', '', '', 1, 25, 'score' , 'asc');
  //   }
     
  // }, [searchFromAreas]);

  
  const handleClear = async () => {
    setSearchName('')
    setSearchArea('')
    setSearchDate('')
    setSearchPriority([])
    sessionStorage.removeItem('qd-tool-search-name');
    sessionStorage.removeItem('qd-tool-search-client-area');
    sessionStorage.removeItem('qd-tool-search-date');
    sessionStorage.removeItem('qd-tool-search-priority');
    setSortBy('score');
    setSortDir('asc');
    setPerPage(25);
    setCurrentPage(1);
    fetchCustomers('', '', '', '', 1, 25, 'score' , 'asc');
  };

  
  useEffect(() => {
    if (props.selectedPriority) {
      setSearchPriority([props.selectedPriority]);
    }
  }, [props.selectedPriority]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const priorityDropdown = document.querySelector('.priority-dropdown');
      const priorityDropdownToggle = document.querySelector('.priority-dropdown-toggle');
  
      if (priorityDropdown && !priorityDropdown.contains(event.target) && event.target !== priorityDropdownToggle) {
        setShowPriorityDropdown(false);
      }
    };
  
    document.addEventListener('mousedown', handleOutsideClick);
  
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  
  const handleDropdownClick = () => {
    setShowPriorityDropdown(!showPriorityDropdown);
  };


  const snoozeClient = (property) => {
    setSnoozedProperty(property);
    setShowSnoozePopup(true);
  };

  const handleSnoozeClose = () => {
    setShowSnoozePopup(false);
    setProperties(properties => properties.filter(property => property !== snoozedProperty));
  };

const handlePerPage = (per_page) => {
  setPerPage(per_page);
  setCurrentPage(1);
  fetchCustomers(searchName, searchArea, searchPriority, searchDate, 1, per_page, sortBy , sortDir);
 
}
const handlePaginationClick = (cur_page) => {
  setCurrentPage(cur_page);
  fetchCustomers(searchName, searchArea, searchPriority, searchDate, cur_page, perPage, sortBy , sortDir);
}

  const handleAreaSelect = (area) => {
    setSearchArea(area);
  }

  const handleNameSelect = (client) => {
    setSearchName(client);
  }

  
const handleSearch = () => {
  setCurrentPage(1);
  sessionStorage.setItem('qd-tool-search-name', searchName ? searchName: '');
  sessionStorage.setItem('qd-tool-search-client-area', searchArea);
  sessionStorage.setItem('qd-tool-search-date', searchDate);
  sessionStorage.setItem('qd-tool-search-priority', JSON.stringify(searchPriority));
  fetchCustomers(searchName, searchArea, searchPriority, searchDate, 1, perPage, sortBy , sortDir);
}

// const handleAreaBasedSearch = () => {
//   setCurrentPage(1);
//   setSortBy('score');
//   setSortDir('asc');
//   setPerPage(25);
//   fetchCustomers('', searchArea, searchPriority, searchDate, 1, 25, 'score' , 'asc');
// }
  

useEffect(() => {
  const storedName = sessionStorage.getItem('qd-tool-search-name');
  const storedArea = sessionStorage.getItem('qd-tool-search-client-area');
  const storedDate = sessionStorage.getItem('qd-tool-search-date');
  const storedPriority = sessionStorage.getItem('qd-tool-search-priority');

  if (storedName || storedArea || storedDate || storedPriority) {
    setSearchName(storedName);
    setSearchArea(storedArea);
    setSearchDate(storedDate);
    const parsedPriorities = JSON.parse(storedPriority);
    setSearchPriority(parsedPriorities);
  /*  setSearchPriority((prevState) => {
      if (prevState.length === 0) {
        return parsedPriorities;
      } else {
        return prevState.map((priority) => {
          return parsedPriorities.includes(priority) ? priority : prevState[prevState.indexOf(priority)];
        });
      }
    });*/

    fetchCustomers(storedName, storedArea, parsedPriorities, storedDate, 1, 25, 'score', 'asc');
  }else{
    fetchCustomers('', '', '', '', 1, 25, 'score' , 'asc');
  }
}, []);




  return (
    <div>
      {(loading) && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>}

      <div>
      <div className='lg:flex justify-between pb-6 pt-5'>
        <div className='mr-auto flex flex-col md:flex-row gap-5'>
        
          {globalClients &&
           <SearchName
           value={searchName || ''}
           onChange={(value) => setSearchName(value)}
           globalClients={globalClients}
           onNameSelect={handleNameSelect}
         />
          }
           {globalAreas && 
           <SearchArea
           value={searchArea || ''}
           onChange={(value) => setSearchArea(value)}
           areas={globalAreas}
           onAreaSelect={handleAreaSelect}
         />
          }
           
            <div>
              <input
                type="date"
                value={searchDate || ''}
                onChange={(e) => setSearchDate(e.target.value)}
                min={minDate}
                max={maxDate}
                placeholder="Service Date"
                className="w-full px-2 border rounded  border-primary/20 focus:border-primary/80 focus:bg-primary-05 focus:ring-0 text-center"
              />
            </div>
            <div className="relative">
            <div onClick={handleDropdownClick} className="priority-dropdown-toggle w-full md:w-60 px-2 border rounded bg-white border-primary/20 hover:border-primary/80 py-2 pr-8 cursor-pointer">
            {searchPriority.length === 0 ? (
              "Search Priority"
            ) : (
              <span className="truncate overflow-hidden">
              {searchPriority.slice(0, 3).map(priority => 
                priority.replace(/\b\w/g, match => match.toUpperCase())
              ).join(", ")}{searchPriority.length > 3 ? "..." : ""}
            </span>
            )}
            </div>
              <div
              className={`absolute mt-2 w-full md:w-60 bg-white border border-primary/20 rounded shadow-md z-10 cursor-pointer priority-dropdown ${showPriorityDropdown ? 'block' : 'hidden'}`}
              >
                {priorities.map((priority, index) => (
                  <div key={index} className="px-4 py-2 hover:bg-slate cursor-pointer" onClick={() => {
                    const currentIndex = searchPriority.indexOf(priority.toLowerCase());
                    const newPriority = [...searchPriority];
                    if (currentIndex === -1) {
                      newPriority.push(priority.toLowerCase());
                    } else {
                      newPriority.splice(currentIndex, 1);
                    }
                    setSearchPriority(newPriority);
                  }}>
                    <input
                      className="text-danger"
                      type="checkbox"
                      id={`priority-${index}`}
                      checked={searchPriority.includes(priority.toLowerCase())}
                      onChange={() => { }}
                    />
                    <span className="ml-2">{priority}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex gap-4 md:inline'>
              <button onClick={handleSearch}
                className='bg-white border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
              </button>
              <button onClick={handleClear}
                className='bg-white border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className='w-full rounded bg-white p-4  shadow-xl'>
          <div className="lg:table align-top min-w-full border pb-3 border-transparent">
            <div className="dark:divide-primary-700 lg:table-row hidden">
              <div className="relative lg:table-cell align-top  overflow-hidden whitespace-nowrap">
                <div className="w-full h-full px-2 py-1 text-left text-xs leading-4 
                            font-bold  uppercase tracking-wider flex items-center focus:outline-none 
                            lg:border-b lg:border-primary/10 cursor-pointer"
                            onClick={() => handleSortOrder('client_name')}>
                              Customer Name
                            {sortBy === 'client_name' ? (sortDir === 'asc' ? '↑' : '↓') : ''}
                            </div>
              </div>
              <div className="relative lg:table-cell  overflow-hidden align-top whitespace-nowrap">
                <div className="w-full h-full px-2 py-1 text-xs leading-4 text-center
                            font-bold  uppercase tracking-wider focus:outline-none 
                            lg:border-b lg:border-primary/10 cursor-pointer"
                            onClick={() => handleSortOrder('service_date')}>
                              Recommended Date
                              {sortBy === 'service_date' ? (sortDir === 'asc' ? '↑' : '↓') : ''}
                              </div>
              </div>
              <div className="relative lg:table-cell  overflow-hidden align-top whitespace-nowrap">
                <div className="w-full h-full px-2 py-1 text-center text-xs leading-4 
                            font-bold  uppercase tracking-wider  focus:outline-none 
                            lg:border-b lg:border-primary/10 cursor-pointer"
                            onClick={() => handleSortOrder('score')}>
                              Priority
                              {sortBy === 'score' ? (sortDir === 'asc' ? '↑' : '↓') : ''}
                              </div>
              </div>
              <div className="relative lg:table-cell overflow-hidden align-top whitespace-nowrap">
                <div className="w-full h-full px-2 py-1 text-center text-xs leading-4 
                            font-bold  uppercase tracking-wider  focus:outline-none 
                            lg:border-b lg:border-primary/10 cursor-pointer"
                            onClick={() => handleSortOrder('duration')}>
                              Duration
                              {sortBy === 'duration' ? (sortDir === 'asc' ? '↑' : '↓') : ''}
                              </div>
              </div>
              <div className="relative lg:table-cell  overflow-hidden align-top whitespace-nowrap">
                <div className="w-full h-full px-2 py-1 text-right text-xs leading-4 
                            font-bold  uppercase tracking-wider focus:outline-none 
                            lg:border-b lg:border-primary/10">Action</div>
              </div>
            </div>

            <div className='lg:hidden w-full block px-2 py-1  pb-4 text-left text-lg leading-4 
                            font-bold  uppercase tracking-wider  items-center focus:outline-none 
                            lg:border-b lg:border-primary/10 cursor-pointer'>
         
                              Customer Details
                            
                            </div>
           


            {(properties) && properties.length !== 0 &&


              properties.map((property, key) =>


                <div key={key} className="lg:table-row p-0 lg:mb-0 lg:hover:bg-primary/20">
                  <div className="border-b border-secondary-200 relative lg:table-cell align-top items-start  px-2 py-1 whitespace-nowrap text-sm     text-left">
                    <div onClick={() => {
                      props.chooseClient(property.account_id, property.property_id)
                    }}
                      className='font-bold cursor-pointer hover:text-danger inline'>
                      {property.client_name}
                    </div>
                    <a className="text-primary hover:text-primary-300 inline-block ml-3  cursor-pointer tooltip" 
                    title="Open in new tab"
                     target="_blank"
                      href= {"https://saniservice.genesyserp.com/contacts/" + (property.account_id)+"/details"}
                     
                      
                      
                      >
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 stroke-current " fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
</svg>    </a>
                    <div className='text-xs pt-1 truncate'>
                      {property.address}
                    </div>
                    <div className='text-xs font-light pt-1 text-primary/70'>
                      {property.phone}</div>
                  </div>
                  <div className="border-b border-secondary-200   lg:table-cell flex align-top  px-2 py-1 whitespace-nowrap text-sm     text-center   ">
                   <div className='md:hidden w-6/12 text-left text-primary/50 text-xs'>Recommended Date</div>
                   <div className='md:block md:w-full w-6/12'>
                    {dayjs(property.service_date).format("MMM DD, YYYY")}
                    </div>


                  </div>
                  <div className="border-b border-secondary-200   lg:table-cell align-top  px-2 py-1 whitespace-nowrap text-sm   flex   text-center   ">
                  <div className='md:hidden w-6/12 text-left text-primary/50 text-xs pt-2'>Priority</div>
                   <div className='md:block md:w-full w-6/12'>
                      <div className='bg-danger/40 rounded text-danger font-normal px-4 py-2 text-xs inline-block w-20'>
                      {property.priority}</div>
                      </div>
                      
                  </div>
                  <div className="border-b border-secondary-200   lg:table-cell align-top  px-2 py-1 whitespace-nowrap text-sm   flex   text-center   ">
                  <div className='md:hidden w-6/12 text-left text-primary/50 text-xs'>Duration</div>
                   <div className='md:block md:w-full w-6/12'>
                   
                    {property.duration} hours
                    </div>

                  </div>
                  <div className="border-b border-secondary-200 lg:mb-0 mb-4  lg:table-cell align-top  px-2 py-1 whitespace-nowrap text-sm  flex   text-right   ">
                    <div className='inline-flex'>
                      <div className='flex space-x-1'>
                        <button onClick={() => {
                          snoozeClient(property)
                        }}
                          className='bg-white border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M10.5 8.25h3l-3 4.5h3" />
                          </svg>
                        </button>
                        <button onClick={() => {
                          props.chooseClient(property.account_id, property.property_id, false)
                        }}
                          className='bg-white border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                          </svg>
                        </button>
                        <button onClick={() => {
                          props.chooseClient(property.account_id, property.property_id, true)
                        }}
                          className='bg-danger/40 border rounded px-2 py-2 cursor-pointer hover:bg-primary/10'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <TablePagination 
          totalRecords={totalRecords}
          perPage={perPage}
          currentPage={currentPage}
          setPerPage={handlePerPage}
          fetchCustomers={handlePaginationClick}
          />
        </div>
      </div>

      {showSnoozePopup && (
        <SnoozePopup 
        property={snoozedProperty} 
        onClose={handleSnoozeClose}
        accountId={snoozedProperty.account_id}
        propertyId={snoozedProperty.property_id}
        userType={props.user_type}
        userId={props.user_id}
        account={props.account}
        />
      )}
    </div>
  );
};

export default TopRatedClients;

