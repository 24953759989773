import React, { useState, useEffect } from 'react'
import axios from '../../api/Axios';
import erpAxios from '../../api/ErpAxios'; 
import Loader from '../../Components/Loader'
import SubHeader from '../../Components/Sales/SubHeader';
import Payment from '../../Components/Sales/Payment'
import PropertyList from '../../Components/Sales/PropertyList'
import Steps from '../../Components/Sales/Steps'
import Calendar from '../../Components/Calendar'
import {useSearchParams} from 'react-router-dom';
import BookingHistory from '../../Components/Sales/BookingHistory';
import dayjs from "dayjs";
function SalesCalander(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);  
  const [clientDetails, setClientDetails] = useState(null);
  const [showProperties, setShowProperties] = useState(false);
  const [activeStep, setActiveStep] = useState(1)
  const [selectedBooking, setSelectedBooking] = useState();
  const [currentMonth, setCurrentMonth] = useState(dayjs().format('MM'));
  const [currentYear, setCurrentYear] = useState(dayjs().format('YYYY'));
  const BASE_URL = '/test/';
  const [properties, setProperties] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [bookedServices, setBookedServices] = useState(null);
  const [showBookingHistory, setShowBookingHistory] = useState(false);
  const [upcomingBookings, setUpcomingBookings] = useState();
  const [ selectedProperty, setSelectedProperty ] = useState(props.selectedProperty);
  const steps = [
    {
      label: 'Booking',
      step: 1,
    },
    {
      label: 'Options',
      step: 2,
    },
    {
      label: 'Payment',
      step: 3,
    },
    {
      label: 'Confirm',
      step: 4,
    },
  ]
  

  const nextStep = () => {
    if (selectedDate) {
      setActiveStep(activeStep + 1)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.body.style.overflow = 'hidden';
      console.log(selectedDate)
    }

  }
  const handleCloseBookingHistory = () => {
    setShowBookingHistory(false);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.body.style.overflow = 'unset';
  }

  const backToHomePage = () => {
    setActiveStep(1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.body.style.overflow = 'unset';
    if (searchParams.has('reference_uuid')) {
      searchParams.delete('reference_uuid');
      setSearchParams(searchParams);
    }
  }

  const totalSteps = steps.length
  const handleUndoSelectedBooking = () => {
    setSelectedBooking(null);
  };

  const fetchClientDetails = async (customerId) => {
    try {
      setClientDetails(null);
      setLoading(true);
      setError(error);
      await erpAxios.get('/service-booking/view-details', {
        params: {
          type: 'client-details',
          account_id: customerId
        }
      })
        .then(response => {
          setClientDetails(eval(response.data.account));
          
          setLoading(false);
          setError(false);
          console.log('Fetched data:', response.data);
        })
        .catch((error) => {
          console.log(error);
          setClientDetails(null);
          setLoading(false);
          setError(true);
        })

    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {
    if(!clientDetails){
      fetchClientDetails(props.selectedClient);
      
    }
  }, [props.selectedClient]);


  useEffect(() => {
    if(clientDetails){
      fetchData(currentMonth, currentYear, props.selectedProperty);
      fetchBookedServices(props.selectedProperty);
    }
  }, [currentMonth, currentYear, clientDetails]);


  const handlePropertyOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setShowProperties(false);
    }
  };
  const handlePropertyChange = (propertyId) => {
    console.log('Selected property ID:', propertyId);
    setShowProperties(false);
    props.setSelectedProperty(propertyId);
    const newPrimaryPropertyAddress = clientDetails.properties[propertyId];
  setClientDetails({ ...clientDetails, primary_property_id: propertyId, primary_property_address: newPrimaryPropertyAddress });

  console.log('Updated clientDetails:', clientDetails);
   // fetchData(currentMonth, currentYear, propertyId)
  };

  const handleMonthNavClick =(month, year) => {
    setCurrentMonth(month)
    setCurrentYear(year)
    fetchData(month, year, props.selectedProperty)
  }
  const fetchData = async (month, year, propertyId) => {
    try {
      setProperties(null);
      setLoading(true);
      setError(error);
      await axios.get(BASE_URL + propertyId, {
        params: {
          month: month,
          year: year
        }
      })
        .then(response => {
          setProperties(eval(response.data));
          setLoading(false);
          setError(false);
          console.log('Fetched data:', response.data);

          
        })
        .catch((error) => {
          console.log(error);
          setProperties(null);
          setLoading(false);
          setError(true);
        })

     

    } catch (error) {
      console.log(error);
    }

  };

  const fetchBookedServices = async (primary_property_id) => {
    try {
      setLoading(true);
        setError(false);
        setBookedServices(null);
        const property_id = primary_property_id;
        await erpAxios.get('/service-booking/online/payment-status', {
          params: {
            selected_property_id: property_id
          }
        })
          .then(resp => {
            console.log(resp)
          setBookedServices(resp.data);
          setLoading(false);
          setError(false);
          console.log('Fetched data:', resp.data);
        })
        .catch((error) => {
          console.log(error);
          setBookedServices(null);
          setLoading(false);
          setError(true);
        })
     

    } catch (error) {
      console.log(error);
    }

  };


  const handleDayClick = (day) => {
    setSelectedDate(day)
  };
  const handleOptionOverlayClick = (event) => {
    if (event.target === event.currentTarget)
    prevStep();
  };
  const handleBookingHistoryOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleCloseBookingHistory();
    }
  };
    const handleModifyBooking = (booking) => {
    setSelectedBooking(booking);
    console.log('Selected booking:', booking);
  };


  const fetchUpcomingBookings = async () => {
    try {
      setUpcomingBookings(null);
      setLoading(true);
      setError(false);
      const token = 'yKuRob1nUYNB0qY3Sx3I4ugSgt2bbAg1CdeAq9qlMi8jzmchhhmbH';
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
  
      await erpAxios.get('recommended-list', {
        params: {
          type: 'upcoming-qd-bookings'
        },
        headers: headers
      })
        .then(response => {
          setUpcomingBookings(response.data);
          setLoading(false);
          setError(false);
        })
        .catch((error) => {
          console.log(error);
          setUpcomingBookings(null);
          setLoading(false);
          setError(true);
        })
  
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (props.showUpcomingBookings && props.selectedClient && !upcomingBookings) {
      fetchUpcomingBookings(props.selectedClient);
    }
  }, [props.showUpcomingBookings, props.selectedClient]);

  useEffect(() => {
    if(upcomingBookings){
      fetchData(currentMonth, currentYear, props.selectedProperty);
      fetchBookedServices(props.selectedProperty);
    }
  }, [currentMonth, currentYear, upcomingBookings]);

return (
    <div>
      {(loading) && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>}
   
      {(clientDetails) &&
      <div>
       <SubHeader  
       clickBookingHistory={() => setShowBookingHistory(true)}
        setShowProperties={setShowProperties} 
        clientDetails={clientDetails}
        selectedProperty={props.selectedProperty}
       />

    {/* Step Begin */}
    <section id="header-section" className=" bg-slate sm:max-w-full">
        <div className="container mx-auto  px-4">

          <Steps selected={activeStep}
          selectedBooking={selectedBooking} 
          items={steps}
          undoSelectedBooking={handleUndoSelectedBooking}
           />
        </div>
      </section>
      {/* Step End */}

   {/* Calendar Begin */}
   <section id="calendar-section" className=" bg-slate sm:max-w-full">
        <div className="container mx-auto  px-4">

          <Calendar currentMonth={currentMonth}
            currentYear={currentYear}
            items={properties}
            onSearch={(month, year) => handleMonthNavClick(month, year)}
            onDaySelect={handleDayClick}
            selectedDate={selectedDate}
            bookedServices={bookedServices}
            upcomingBookings={upcomingBookings}
            selectedBooking={selectedBooking}
            showUpcomingBookings={props.showUpcomingBookings} 
          />
        </div>
      </section>
      {/* Calendar End */}
             {/* Booking History Begin */}
             <section id="booking-history-section" className={`${showBookingHistory ? "" : "hidden"}`}>
      <div className="bg-white/50 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm"></div>
      <div className="flex absolute inset-0 h-screen items-center" onClick={handleBookingHistoryOverlayClick}>
      <div className="md:w-100 flex bg-white rounded gap-1 shadow-2xl shadow-primary/20 md:mx-auto md:py-5 md:px-8 mx-12 px-9 py-6">
          <BookingHistory 
            selectedPropertyId={props.selectedProperty}
            onClose={handleCloseBookingHistory}
            onModifyBooking={handleModifyBooking}
            showBookingHistory={showBookingHistory}
            clientDetails={clientDetails}
            bookedServices={bookedServices}
            // onCancelBooking={handleCancelBooking}
          />
        </div>
      </div>
    </section>
    {/* Booking History End */}
<section id="property-section" className={`${showProperties ? '' : 'hidden'} sm:max-w-full`}>
<div className="bg-white/10 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm" onClick={handlePropertyOverlayClick}></div>
        <div className="flex absolute top-44 left-0 right-0 h-auto items-center justify-center">
        <div className="w-96 bg-white  rounded gap-1 shadow-2xl shadow-primary/20 mx-auto py-5 px-8">
      <PropertyList 
        visible={showProperties} 
        setVisible={setShowProperties} 
        properties={clientDetails.properties} 
        handlePropertyChange={handlePropertyChange}
        onClose={() => setShowProperties(false)}
        selectedPropertyId={props.selectedProperty}
        clientDetails={clientDetails}
      />
    </div>
  </div>
</section>


 {/* Options Begin */}
 <section id="option-section" className={`${activeStep === 2 ? "" : "hidden"}`}>
        <div className="bg-white/50 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm"></div>
        <div className="flex absolute inset-0 h-screen w-screen items-center" onClick={handleOptionOverlayClick}>
          <div className="md:w-96 w-54 bg-white  rounded gap-1 shadow-2xl shadow-primary/20 mx-auto py-5 px-8">
            <Payment
              //handlePaymentClick={handlePaymentClick}
              primaryPropertyAddress={clientDetails.primary_property_address}
              doPreviousStep={prevStep}
              items={properties}
              selectedDate={selectedDate}
              selectedBooking={selectedBooking}
              clientDetails={clientDetails}
              account={props.account}
            />

          </div>
        </div>
      </section>
      {/* Options End */}





<section id="button-section" className=" bg-slate">
        <div className="container mx-auto md:px-4 px-8">
          <div className="pb-14">
            <p className="pb-7 md:text-sm text-xs text-primary/70 md:text-left text-center">All prices are shown in AED. Conditions may apply for each price. Availability and price are not guaranteed until you receive a booking confirmation email containing a booking reference.*</p>

            <div className={`flex justify-center md:block ${(activeStep > 1 && activeStep < totalSteps) ? 'flex justify-between' : (activeStep === totalSteps) ? 'md:text-left' : 'md:text-right'} `}>

              {/* <button
                onClick={prevStep}
                className={`text-white text-2xl font-bold py-3.5 px-32 rounded bg-danger hover:bg-danger/80
            ${activeStep === 1 ? 'hidden' : ''}
            `}>
                Previous
              </button> */}

              <button
  onClick={nextStep}
  className={`text-white md:text-2xl text-md font-bold py-2 md:py-3.5 px-32 rounded 
    ${activeStep === totalSteps ? 'hidden' : ''}
    ${selectedDate ? "bg-danger hover:bg-danger/80" : 'bg-danger/50 cursor-not-allowed'}
    flex justify-center md:inline-block
  `}
>
  Continue
</button>
            </div>
          </div>
        </div>
      </section>


</div>




      }


  </div>
);
}
export default SalesCalander