import dayjs from 'dayjs';
import React, { useState } from 'react';


const ConfirmPayment  = (props) => {
  
  const [copied, setCopied] = useState(false);

  const copyPaymentLink = () => {
    navigator.clipboard.writeText(props.paymentLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

   
  return (
    <div className="w-full">

<div className="w-full flex justify-end mb-3">
                <button onClick={props.onCancel}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"
                        width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333" />
                    </svg>
                </button>
            </div>

<p className="md:text-2xl text-xl font-light">{props.clientDetails?.client_name},</p>
                
      <div className="mt-5 ">
      <p className="md:text-sm text-xs font-normal pb-4">Please review the booking details provided below:</p>
      <div className="w-full py-2 border-primary/30">
  <div className="table align-middle w-full mb-4">
    <div className="table-row text-xs">
      <div className="table-cell  w-4/12 border-b py-2 px-1">
        Location:
      </div>
      <div className="table-cell border-b py-2 font-bold">
       {props.primaryPropertyAddress}
      </div>
    </div>
    <div className="table-row text-xs">
      <div className="table-cell  border-b py-2">
      Date:
      </div>
      <div className="table-cell  border-b  py-2 font-bold">
      {dayjs(props.selectedDate).format('MMMM D, YYYY')}
      </div>
    </div>
    <div className="table-row text-xs">
      <div className="table-cell  border-b  py-2">
      Time:
      </div>
      <div className="table-cell  border-b  py-2 font-bold">
      {props.selectedTimeSlot}
      </div>
    </div>
    <div className="table-row text-xs">
      <div className="table-cell  border-b  py-2">
      Amount:
      </div>
      <div className="table-cell  border-b  py-2 font-bold">
      {props.total_amount}
      </div>
    </div>
  
  </div>
</div>

{props.paymentLink && (
          <div className="w-full">
            <p className="md:text-xl text-lg font-light">Your payment link:</p>
            <div className="w-full py-2 border-primary/30">
            <p className="md:text-sm text-xs font-normal">
              {copied ? <span className="text-danger bg-slate-10">COPIED!</span> : null}
              </p>
              <p className="md:text-sm text-xs font-normal break-words">
                {props.paymentLink}
                </p>
                </div>
            <div className="flex justify-center mb-4">
              <button className="w-full text-white py-2 text-sm rounded font-bold bg-danger hover:bg-danger/80" onClick={copyPaymentLink}>
                Copy
              </button>
              {/* <button className="w-12 mx-4 text-center text-white py-2 text-sm rounded font-bold bg-danger hover:bg-danger/80" onClick={props.onCancel}>
                Close
              </button> */}
            </div>
          </div>
        )}
        <div className="w-full">
        
        {props.paymentLink ? null : (
          <button className="w-full text-white py-2 text-sm rounded font-bold bg-danger hover:bg-danger/80"
          onClick={props.onConfirm}>
            Send Payment Link
          </button>
        )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
