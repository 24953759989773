import React, { useState, useRef, useEffect } from 'react';

const SearchName = (props) => {
  const [filteredNames, setFilteredNames] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue !== '' && props.globalClients) {
      const filteredNames = props.globalClients.filter((client) =>
        client.toLowerCase().includes(inputValue.toLowerCase())
      ).slice(0, 5);
      setFilteredNames(filteredNames);
    } else {
      setFilteredNames([]);
    }
    props.onChange(inputValue);
  };

  const handleNameSelect = (client) => {
    props.onNameSelect(client);
    setFilteredNames([]);
  };

  const handleOutsideClick = (e) => {
    if (
      inputRef.current &&
      dropdownRef.current &&
      !inputRef.current.contains(e.target) &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShowDropdown(false);
    }
  };

  const handleNameInput = () => {
    setShowDropdown(true);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative">
      <input
        ref={inputRef}
        type="text"
        value={props.value}
        onChange={handleNameChange}
        onClick={handleNameInput}
        placeholder="Name/Mobile/Email"
        className="w-full md:w-60 px-2 border rounded  border-primary/20 focus:border-primary/80 focus:bg-primary-05 focus:ring-0"
      />
      {showDropdown && filteredNames.length > 0 && (
        <ul
          ref={dropdownRef}
          className={`absolute bg-white border rounded shadow-md z-10 w-full md:w-60 ${
            filteredNames.length > 0 ? '' : 'hidden'
          }`}
        >
          {filteredNames.map((name, index) => {
            const inputValue = props.value.toLowerCase();
            const startIndex = name.toLowerCase().indexOf(inputValue);
            const endIndex = startIndex + inputValue.length;
            const matchedText = name.substring(startIndex, endIndex);
            const beforeMatch = name.substring(0, startIndex);
            const afterMatch = name.substring(endIndex);

            return (
              <li
                key={index}
                className="px-4 py-2 hover:bg-primary/10 cursor-pointer"
                onClick={() => handleNameSelect(name)}
              >
                {beforeMatch}
                <span className="bg-danger/40">{matchedText}</span>
                {afterMatch}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SearchName;