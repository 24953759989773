import React, { useState, useEffect } from 'react';
import erpAxios from '../../api/ErpAxios';
import Loader from '../Loader';
import dayjs from 'dayjs';
import CancelBooking from './CancelBooking';
import ModifyBooking from './ModifyBooking';

const BookingHistory = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);  
  const CANCEL_URL = '/service-booking/online/bookings';
  const [cancelBookingOpen, setCancelBookingOpen] = useState(false);
  const [selectedServiceDate, setSelectedServiceDate] = useState(props.service_date);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [modifyBookingOpen, setModifyBookingOpen] = useState(false);
  const [serviceToModify, setServiceToModify] = useState(null);
  useEffect(() => {
    if (props.showBookingHistory) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [props.showBookingHistory]);

  if (loading) {
    return <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>;
  }

  if (error) {
    return <div>Error fetching data</div>;
  }

  if (!props.bookedServices) {
    return <div>No data available</div>;
  }

  const services = Object.values(props.bookedServices.services);

  const titles = [
    'SERVICE DATE / TIME',
    'SERVICE PRICE',
    'TOTAL PRICE',
    'ACTION',
  ];

  const handleModifyBooking = (service) => {
    setModifyBookingOpen(true);
    setServiceToModify(service);
  };

  const handleModifyBookingClose = () => {
    setModifyBookingOpen(false);
  };

  const handleModifyBookingConfirm = () => {
    setModifyBookingOpen(false);
    props.onModifyBooking(serviceToModify);
    props.onClose();
  };

  const handleCancelBooking = (service) => {
    setSelectedServiceDate(service.service_date);
    setCancelBookingOpen(true);
    setServiceToDelete(service);
  };

  const handleCancelBookingConfirm = () => {
    erpAxios.delete(CANCEL_URL+'/'+serviceToDelete.booking_id, {
      data: {
        reference_uuid: serviceToDelete.booking_id
      }
    })
    .then(response => {
      console.log(response);
      setCancelBookingOpen(false);
    //  props.fetchData();
    })
    .catch(error => {
      console.log(error);
    });
  };

  const handleCancelBookingClose = () => {
    setCancelBookingOpen(false);
  };

  return (
    <div>
    <div className="md:w-full flex justify-end mb-2">
                <button onClick={props.onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"
                    width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333" />
                </svg>
                </button>
            </div>
            <p className="md:text-2xl text-lg font-light mb-2">Dear {props.account?.client_name},</p>
            <div className={`${services.length === 0 ? 'md:w-96 w-full' : ''} flex flex-col justify-start items-start gap-8 my-4`}>
        {services.length > 0 ? (
          <p className="text-md font-light text-primary">Please review your service bookings below and click <span className="md:text-md text-sm font-bold">Modify Booking</span> if any changes are needed.</p>
        ) : (
          <p className="md:text-lg text-md font-light text-primary text-center p-4">Don’t have any bookings right now? Perfect time to schedule your next service! We’re eager to assist and make sure you get exactly what you need. Go ahead and book now—we’re excited to help!</p>
        )}
      </div>
      {services.length > 0 && (
        <div className="table align-middle w-full my-8">
          <div className="grid grid-cols-4 gap-2 text-center rounded bg-slate">
            {titles.map((title, index) => (
              <div key={index} className="text-md text-primary">
                <div className="my-6 font-bold">{title}</div>
              </div>
            ))}
          </div>
          {services.map((service, index) => (
            <div key={index}>
              <div className="grid grid-cols-4 gap-2 text-center border-b border-secondary py-4">
                <div className="text-md text-primary">
                  <div>{dayjs(service.service_date).format('MMMM D, YYYY')} / {service.start_time}</div>
                </div>
                <div className="text-md text-primary">
                  <div>{service.service_amount}</div>
                </div>
                <div className="text-md text-primary">
                  <div>{service.total_amount}</div>
                </div>
                <div className="text-center flex self-end">
                  <div onClick={() => handleModifyBooking(service)} className="text-primary text-md underline cursor-pointer mr-2">Modify Booking</div>
                  <div onClick={() => handleCancelBooking(service)} className="text-danger text-md underline cursor-pointer ml-2">Cancel Booking</div>
                </div>
              </div>
            </div>
          ))}
      </div>
      )}
        {services.length === 0 ? (
          <button onClick={props.onClose} className="w-full bg-danger text-white py-2 text-sm rounded font-bold hover:bg-danger/90">Book Now</button>
        ) : (
        <button onClick={props.onClose} className="w-full bg-danger text-white py-2 text-sm rounded font-bold hover:bg-danger/90">Close</button>
        )}

        {cancelBookingOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
        <CancelBooking
          onCancel={handleCancelBookingClose}
          onConfirm={handleCancelBookingConfirm}
          onClose={() => props.onClose()}
          account={props.account}
          service_date={selectedServiceDate}
        />
        </div>
      )}
      {modifyBookingOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <ModifyBooking
            onCancel={handleModifyBookingClose}
            onConfirm={handleModifyBookingConfirm}
            onClose={() => props.onClose()}
            account={props.account}
            service={serviceToModify}
          />
        </div>
      )}
    </div>
  );
};

export default BookingHistory;

