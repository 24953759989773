import React from "react";
import { useNavigate } from "react-router-dom";
function Footer(){
    const currentYear = new Date().getFullYear();
    let navigate = useNavigate();
    const handleTerms = () => {
        navigate("/terms");
      }
      const handleUsage = () => {
        navigate("/site-usage");
      }
      


  return (

    <footer className="bg-primary w-full">

    <div className="container md:mx-auto flex justify-between items-end px-4">

       
        <div className="flex flex-col md:w-11/12 w-full h-full flex-start md:flex-1 flex-grow">

            <div className="border-b-0-2 w-full md:py-7 py-5">
         

            </div>
            <div className="flex flex-wrap md:flex-nowrap md:gap-10 md:py-7 py-4">
            <div className="text-white md:text-sm text-xs md:block md:font-medium pb-4 md:pb-0">© {currentYear} Saniservice. All rights reserved</div>
            <div className="flex gap-10">
                <a href="https://saniservice.com/contact-us/"
                target="_blank"
                 className="text-white text-xs border-b font-normal md:block hidden">Contact Us</a>
                <a href="https://saniservice.com/blog/"
                target="_blank"
                 className="text-white text-xs border-b font-normal md:block hidden">Blog</a>
                   <a href="javascript:void(0);"  onClick={handleUsage}
                 className="text-white text-xs border-b font-normal md:block hidden">Site Usage</a>
                <a href="javascript:void(0);"  onClick={handleTerms}
                 className="text-white text-xs border-b font-normal md:block hidden">Terms & Conditions</a>
            </div>


            </div>
         
        </div>
       <div className="md:w-11/12 md:block h-full flex-1">
       <div className="bg-danger md:w-32 w-28 p-2.5 mt-6 md:ml-auto ml-9">
            <img src="../Logo.png" alt="logo" 
            className="h-8 mb-16 mx-auto" />
        </div>

       </div>
    </div>


</footer>


       
    );
}
export default Footer;