import { useState } from "react";
import dayjs from "dayjs";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Nav from "./Calendar/Nav";
import Header from "./Calendar/Header";
import Day from "./Calendar/Day";
const Calendar = (props) => {
    const now = dayjs();
    dayjs.extend(weekdayPlugin);
    dayjs.extend(objectPlugin);
    dayjs.extend(isTodayPlugin);
    dayjs.extend(isSameOrBefore);
    const [currentMonth, setCurrentMonth] = useState(now);
   
    const nextMonth = () => {
        const plus = currentMonth.add(1, "month");
        setCurrentMonth(plus);
        props.onSearch(plus.format("MM"), plus.format("YYYY"), props.selectedPropertyId);
    };

    const prevMonth = () => {
        const minus = currentMonth.subtract(1, "month");
        setCurrentMonth(minus);
        props.onSearch(minus.format("MM"), minus.format("YYYY"), props.selectedPropertyId);
    };

    const handleDayClick = (day) => {
       props.onDaySelect(day)
    };

  

    return (
        <div className="calendar md:pb-14 pb-6">
          
           <Nav currentMonth={currentMonth} 
           onPrevious={prevMonth} 
           onNext={nextMonth} />
<Header now={now}/>
            <Day currentMonth={currentMonth} 
            selectedDate={props.selectedDate}
            items={props.items}
             onSelect={handleDayClick}
             bookedServices={props.bookedServices}
             upcomingBookings={props.upcomingBookings}
             showUpcomingBookings={props.showUpcomingBookings}
               />
        </div>
    );
};

export default Calendar;