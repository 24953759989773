import React from "react";

function SubHeader(props) { 

    const handleClick = () => {
        props.setShowProperties(!props.showProperties);
    }
   
    return (
        <div className="bg-white md:py-7 py-4">

            <div className="container md:mx-auto px-4">
                
               
               <div className="md:text-lg text-xs font-light mb-0.5">{props.account.client_name}</div>
               <div className="md:text-base text-xs font-light text-primary/80 flex items-baseline gap-4">
               {props.account.primary_property_address}  
              
              {Object.keys(props.account.properties).length > 1 && (
               <p
               className="text-danger md:text-base text-xs hover:text-danger/80 cursor-pointer md:border-b border-danger text-right hover:border-danger/80 leading-none"
               onClick={handleClick}
               >Change Location</p>
              
            )}
 </div>
 

            </div>



        </div>
    );
}
export default SubHeader;