import React, { useState, useEffect } from "react";

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    props.clickLogout();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownOpen && !event.target.closest(".relative")) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownOpen]);

  return (
    <header className="bg-primary">
      <div className="container mx-auto flex justify-between items-center px-4">
        <div className="bg-danger md:w-52 w-28 md:p-5 p-2">
          <img src="../Logo.png" alt="logo" className="md:w-11/12 md:mx-auto" />
        </div>
        <div className="relative flex md:gap-4 gap-2">
          <div className="text-white border-dotted border-r pr-4 hover:text-danger cursor-pointer"
          onClick={props.gotoDashboard}

          
          
          >Dashboard</div>
          <button
            className="text-white hover:text-white/50 group gap-1 md:text-base text-xs font-normal flex cursor-pointer"
            onClick={handleDropdownToggle}
          >
          
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
<g clipPath="url(#clip0_528_9387)">
<path fillRule="evenodd" clipRule="evenodd" d="M8.80819 2.20411C6.84182 2.20411 5.24256 3.81843 5.24256 5.80331C5.24256 7.78819 6.84182 9.40248 8.80819 9.40248C10.7746 9.40248 12.3738 7.78819 12.3738 5.80331C12.3738 3.81843 10.7746 2.20411 8.80819 2.20411ZM8.80819 11.1059C5.91096 11.1059 3.55469 8.72744 3.55469 5.80296C3.55469 2.87845 5.91096 0.5 8.80819 0.5C11.7055 0.5 14.0617 2.87845 14.0617 5.80296C14.0617 8.72744 11.7055 11.1059 8.80819 11.1059Z" fill="white" fillOpacity="1"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.2214 19.3039C15.2082 18.1133 14.0339 17.1107 12.7425 16.4209C11.4526 15.732 10.0439 15.363 8.64413 15.4245C7.24442 15.4513 5.87968 15.9057 4.6598 16.6509C3.43829 17.3985 2.35594 18.4341 1.43798 19.6345C1.15152 20.0133 0.638013 20.065 0.292148 19.7504C-0.0480186 19.4412 -0.0968465 18.8877 0.175776 18.5098C1.18 17.1294 2.42511 15.9227 3.88343 15.0805C5.33362 14.2312 6.99703 13.7856 8.64413 13.8204C10.2929 13.8729 11.9115 14.4041 13.2909 15.3113C14.6792 16.2115 15.8332 17.4502 16.7415 18.8405C16.8496 19.0062 16.8146 19.2353 16.6641 19.3538C16.5249 19.4625 16.3345 19.4376 16.2214 19.3039Z" fill="white" fillOpacity="1"/>
</g>
<defs>
<clipPath id="clip0_528_9387">
<rect width="17.5" height="20" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

          </button>
          {dropdownOpen && (
            <div className="absolute right-0 top-full w-[149px]  p-2.5 bg-white rounded shadow flex-col justify-start items-start gap-1 inline-flex">
              <div className="self-stretch p-2 rounded-xl justify-start items-center gap-1 inline-flex cursor-pointer hover:bg-slate"
               onClick={() => {setDropdownOpen(false)}}>
                <div className="grow shrink basis-0 h-5 rounded-lg justify-start items-center gap-2 flex">
                  <div className="grow shrink basis-0 rounded-lg flex-col justify-center items-start inline-flex">
                    <div className="self-stretch text-primary text-sm font-normal font-['SF Pro Display'] leading-tight">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            
              <div
                className="self-stretch p-2 rounded-xl justify-start items-center gap-1 inline-flex cursor-pointer hover:bg-slate"
                onClick={() => {
                  handleLogout();
                  setDropdownOpen(false);
                }}
              >
                <div className="grow shrink basis-0 h-5 rounded-lg justify-start items-center gap-2 flex">
                  <div className="grow shrink basis-0 rounded-lg flex-col justify-center items-start inline-flex">
                    <div className="self-stretch text-primary text-sm font-normal font-['SF Pro Display'] leading-tight">
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;