import dayjs from 'dayjs';
import React from 'react'
const Steps = (props) => {
     return (
       <div> <div className=''>
        {props.selectedBooking && (
            <div>
                <div className='text-2xl lg:text-4xl font-semibold py-7'>Modify Booking</div>

<div>
<div className="inline-flex text-left bg-white py-2  mb-7 text-xs shadow-2xl rounded relative">
<button title='Undo Selected Booking'
onClick={props.undoSelectedBooking}
className='absolute -right-5 -top-5'><svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" width="30" height="30" viewBox="0 0 30 30" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333"></path></svg></button>
      <div className="gap-2 flex flex-col border-r border-dashed px-3 min-w-52">
        <div className="font-bold">SERVICE DATE / TIME</div>
        <div className='text-sm'>{dayjs(props.selectedBooking.service_date).format('MMMM D, YYYY')} / {props.selectedBooking.start_time}</div>
      </div>
      <div className="gap-2 flex flex-col border-r border-dashed px-3 min-w-52">
        <div className="font-bold text-right">SERVICE PRICE</div>
        <div className='text-right text-sm'>{props.selectedBooking.service_amount}</div>
      </div>
      <div className="gap-2 flex flex-col border-r border-dashed px-3 min-w-52">
        <div className="font-bold text-right">VAT</div>
        <div className='text-right text-sm'>{props.selectedBooking.vat_amount}</div>
      </div>
      <div className="gap-2 flex flex-col px-3 min-w-52">
        <div className="font-bold text-right">TOTAL PRICE</div>
        <div className='text-right font-bold text-sm text-danger'>{props.selectedBooking.total_amount}</div>
      </div>
    </div>
    </div>
    </div>


        )}
        {!props.selectedBooking && (
                <p className='text-2xl lg:text-4xl font-semibold py-7'>Make A Booking</p>
        )}
        

      </div>
        <div className="grid grid-cols-4 gap-5">

            {props.items.map(({ step, label }) => (

                <div className={`md:border-b-8 border-b-4 
                ${step === props.selected ? 'border-primary' : 'border-primary/20'}
                
                flex gap-2.5 pb-4 items-end`} key={step}

                >
                    <div className={`lg:text-5xl md:text-3xl ${step === props.selected ? 'text-danger font-bold' : 'text-primary/80 font-light'} `}>
                        {step}
                    </div>
                    <div className={`lg:text-lg text-base ${step === props.selected ? 'font-bold' : 'font-light text-primary/80'} `}>{label}</div>
                </div>

            ))}


</div>


        </div>
    );
}
export default Steps;