import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useToken from '../../Components/Sales/useToken';
import useAccount from '../../Components/Sales/useAccount';
import Header from '../../Components/Sales/Header';
import Login from '../../Components/Sales/Login';
import Dashboard from './Dashboard';
import SalesCalander from './SalesCalander';

function Home(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { account, setAccount } = useAccount();
  const { token, setToken } = useToken();
  const [ selectedClient, setSelectedClient] = useState();
  const [ selectedProperty, setSelectedProperty ] = useState();
  const [showUpcomingBookings, setShowUpcomingBookings] = useState(false);

  const logout = () => {
    setToken('')
    setAccount('')
    sessionStorage.removeItem("sales_account_token");
    sessionStorage.removeItem("sales_account_details");
  }

  if (!token || !account) {
    return <Login setToken={setToken} setAccount={setAccount} />
  }
  
  const gotoDashboard= () => {
    setSelectedClient(null);
  }
  const chooseClient= (id) => {
    console.log('dfds')
    console.log(id)
    setSelectedClient(id);
  }
console.log(selectedClient)
 
  return (

    <div className="">

      <Header 
      clickLogout={logout}
      gotoDashboard ={gotoDashboard}
      setSelectedClient={setSelectedClient}
      setSelectedProperty={setSelectedProperty}
      
      />

      
      {(!selectedClient) &&
        <Dashboard 
        account={account}
        setSelectedClient={setSelectedClient}
        setSelectedProperty={setSelectedProperty}
        setShowUpcomingBookings={setShowUpcomingBookings}

         />
      }
      {(selectedClient) &&
        <SalesCalander 
          account={account}
          selectedClient={selectedClient}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          showUpcomingBookings={showUpcomingBookings}
         />
      }



    </div>
  );
}
export default Home