import React from 'react'

function Loader({ fullHeight = false }){
    return (
        <div className={`flex space-x-2 justify-center items-center ${fullHeight ? 'h-screen' : ''}`}>
        <span className='sr-only'>Loading...</span>
         <div className='h-8 w-8 bg-danger/50 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
       <div className='h-8 w-8 bg-danger/50 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
       <div className='h-8 w-8 bg-danger/50 rounded-full animate-bounce'></div>
   </div>
    )
}

export default Loader