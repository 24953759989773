import React, { useState, useRef, useEffect } from 'react';

const SearchArea = (props) => {
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleAreaChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue !== '' && props.areas) {
      const filteredAreas = props.areas.filter((area) =>
        area.toLowerCase().includes(inputValue.toLowerCase())
      ).slice(0, 5);
      setFilteredAreas(filteredAreas);
    } else {
      setFilteredAreas([]);
    }
    props.onChange(inputValue);
  };

  const handleAreaSelect = (area) => {
    props.onAreaSelect(area);
    setFilteredAreas([]);
  };

  const handleOutsideClick = (e) => {
    if (
      inputRef.current &&
      dropdownRef.current &&
      !inputRef.current.contains(e.target) &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShowDropdown(false);
    }
  };

  const handleAreaInput = () => {
    setShowDropdown(true);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative">
      <input
        ref={inputRef}
        type="text"
        value={props.value}
        onChange={handleAreaChange}
        onClick={handleAreaInput}
        placeholder="Area"
        className="w-full md:w-60 px-2 border rounded  border-primary/20 focus:border-primary/80 focus:bg-primary-05 focus:ring-0"
      />
      {showDropdown && filteredAreas.length > 0 && (
        <ul
          ref={dropdownRef}
          className={`absolute bg-white border rounded shadow-md z-10 w-full top-full left-0 max-h-screen overflow-y-auto ${
            filteredAreas.length > 0 ? '' : 'hidden'
          }`}
        >
          {filteredAreas.map((area, index) => {
            const inputValue = props.value.toLowerCase();
            const startIndex = area.toLowerCase().indexOf(inputValue);
            const endIndex = startIndex + inputValue.length;
            const matchedText = area.substring(startIndex, endIndex);
            const beforeMatch = area.substring(0, startIndex);
            const afterMatch = area.substring(endIndex);

            return (
              <li
                key={index}
                className="px-4 py-2 hover:bg-primary/10 cursor-pointer"
                onClick={() => handleAreaSelect(area)}
              >
                {beforeMatch}
                <span className="bg-danger/40">{matchedText}</span>
                {afterMatch}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SearchArea;