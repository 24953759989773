import React, { useState, useEffect } from 'react'

import Loader from '../../Components/Loader'
import TopRatedAreas from '../../Components/Sales/TopRatedAreas';
import TopRatedClients from '../../Components/Sales/TopRatedClients';
function Dashboard(props) {
  
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);  
  const [searchArea, setSearchArea] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [searchPriority, setSearchPriority] = useState('');
  const [activeTab, setActiveTab] = useState('topRatedClients');
  const [searchFromAreas, setSearchFromAreas] = useState(false);


  const chooseClient = (client_id, property_id, showUpcomingBookings) => {
      props.setShowUpcomingBookings(showUpcomingBookings)
      props.setSelectedClient(client_id)
      props.setSelectedProperty(property_id)
  };
  
  const handleClientSearch = (area, priority, searchDate) => {
    sessionStorage.setItem('qd-tool-search-client-area', area);
    sessionStorage.setItem('qd-tool-search-date', searchDate);
    console.log(priority);
    if(priority){
      sessionStorage.setItem('qd-tool-search-priority', JSON.stringify([priority]));
    }else{
      sessionStorage.setItem('qd-tool-search-priority', JSON.stringify([]));
    }
    setActiveTab('topRatedClients');
    setSearchArea(area);
    setSearchPriority(priority);
    setSearchDate(searchDate);
    setSearchFromAreas(true); 
  };
  


  
  

  

  return (
    <div>
      {(loading) && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>}
      <div className="bg-white md:py-7 py-4">
        <div className="container md:mx-auto px-4">
          <div className="md:text-lg text-xs font-light mb-0.5">Dear {props.account.user_name},</div>
          <div className="md:text-base text-xs font-light text-primary/80 flex items-baseline gap-4">
            Please Choose the Details
          </div>
        </div>
      </div>
      <section id="header-section" className=" bg-slate sm:max-w-full">
        <div className="container mx-auto  px-4">
          <div className='text-2xl lg:text-4xl font-semibold pt-7'>Sales Dashboard</div>
        </div>
      </section>
      <section id="header-section" className=" bg-slate sm:max-w-full">
        <div className="container mx-auto  px-4 pb-8">
          <div className='flex justify-between py-14'>
            <div className='flex gap-10'>
               <div 
                className={`text-lg font-normal pb-2.5 border-${activeTab === 'topRatedClients' ? 'danger' : 'slate'} border-b-4 cursor-pointer
                hover:text-primary/80 hover:border-primary/50`}
                
                onClick={() => {
                  setActiveTab('topRatedClients');
                  setSearchFromAreas(false);
                }}>

              
                Top Rated Clients
              </div>
              <div 
                className={`text-lg font-normal pb-2.5 border-${activeTab === 'topRatedAreas' ? 'danger' : 'slate'} border-b-4 cursor-pointer
                hover:text-primary/80 hover:border-primary/50`}
                onClick={() => {
                  setActiveTab('topRatedAreas');
                  setSearchFromAreas(false);
                }}
              >
                Top Rated Areas
              </div>
            </div>

          </div>
          {activeTab === 'topRatedClients' && (
         <TopRatedClients 
         chooseClient={chooseClient}
         selectedArea={searchArea}
         selectedPriority={searchPriority}
         selectedDate={searchDate}
         account={props.account}
         searchFromAreas={searchFromAreas}
         
         />
          )}

{activeTab === 'topRatedAreas' && (
          <div>
<TopRatedAreas 
handleClientSearch={handleClientSearch}
setActiveTab={setActiveTab}
/>

          </div>
            )}
        </div>
      </section>
      <section id="calendar-section" className=" bg-slate sm:max-w-full hidden">
        <div className="container mx-auto  px-4 pb-10 flex flex-col">
          <div className='pb-8'>
            <h1 className='text-2xl font-bold'>Mr. Francois Larsen</h1>
            <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
              onClick={() => {
                chooseClient(16307, 15348)
              }}>
              Villa No. 17B, 41A St., Jumeirah 1, Dubai, UAE</p>
          </div>
          <div className='pb-8'>
            <h1 className='text-2xl font-bold'>Mr. Paul Hendry</h1>
            <div className='flex flex-col'>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(5475, 4213)
                }}>
                Seventh Heaven, Unit No. AA 0211, Floor 2, Al Barari, Dubai, UAE</p>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(5475, 4212)
                }}>
                Al Majara Tower 1, Unit No. 1807, Dubai Marina, Dubai, UAE</p>
            </div>
          </div>
          <div className='pb-8'>
            <h1 className='text-2xl font-bold'>Mr. Marc Anderson</h1>
            <div className='flex flex-col'>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(2630, 1260)
                }}>
                West Tower, Unit No. 2505, Marina Quays, Dubai Marina, Dubai, UAE</p>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(2630, 31811)
                }}>
                East Tower, Unit No. 910, Marina Quays, Dubai Marina, Dubai, UAE</p>
            </div>
          </div>
          <div className='pb-8'>
            <h1 className='text-2xl font-bold'>Mr. Almin Lisic</h1>
            <div className='flex flex-col'>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(17004, 16187)
                }}>
                Ruby Tower, Unit No. 105, Tiara Residence, Palm Jumeirah, Dubai, UAE</p>
            </div>
          </div>
          <div className='pb-8'>
            <h1 className='text-2xl font-bold'> Mr. Vijay Jaswal</h1>
            <div className='flex flex-col'>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(16666, 15778)
                }}>
                Villa No. 91, Saheel St., Saheel 4, Arabian Ranches, Dubai, UAE</p>
              <p className='hover:text-danger cursor-pointer text-lg px-4 py-1 inline-block'
                onClick={() => {
                  chooseClient(16666, 15777)
                }}>
                Murjan 3, Unit No. 103, Murjan, Jumeirah Beach Residence, Dubai, UAE</p>
            </div>
          </div>
          <br></br>
        </div>
      </section>
    </div>
  );
}
export default Dashboard