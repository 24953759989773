import dayjs from 'dayjs';
import React from 'react';

const CancelBooking = (props) => {
  return (
<div className="flex justify-center items-center">
  <div className="max-w-md border rounded bg-white border-secondary p-5 shadow-2xl shadow-primary/20">
   
      <div>
        <p className="md:text-2xl text-xl font-light pb-1.5">
        Booking Cancellation..</p>
        <p className="md:text-md text-sm pb-5">
        Are you sure you want to cancel service on 
        <span className="md:text-md text-sm font-bold pl-1">{dayjs(props.service_date).format('MMMM D, YYYY')}</span>
        ?</p>
        <div className="w-full justify-end items-center gap-2 inline-flex">
  <div className=" text-white py-2 text-sm  font-bold w-24 h-8 p-1 rounded border border-danger items-center hover:bg-slate cursor-pointer"
  onClick={props.onCancel}>
    <div className="justify-center items-center gap-1 flex">
      <div className="text-center text-danger text-xs font-medium leading-tight">
        Dismiss
      </div>
    </div>
  </div>
  <div className=" bg-danger rounded border border-danger justify-center items-center hover:bg-danger/90 cursor-pointer
  
  text-white py-2 text-sm  font-bold w-24 h-8 p-1 
  "
  onClick={props.onConfirm}>
    <div className="justify-center items-center gap-1 flex">
      <div className="text-center text-white text-xs font-medium leading-tight">
        Confirm
      </div>
    </div>
  </div>
</div>
      </div>
      </div>
    </div>
  );
};

export default CancelBooking;