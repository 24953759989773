import { useState } from 'react';

export default function useAccount() {
  const getAccount = () => {
    const val = sessionStorage.getItem('account_details');
    return JSON.parse(val)
  };

  const [account, setAccount] = useState(getAccount());

  const saveAccount = userAccount => {
   
    sessionStorage.setItem('account_details', JSON.stringify(userAccount));
    setAccount(userAccount);
  };

  return {
    setAccount: saveAccount,
    account
  }
}
